/**colors***/
$gray: #919191;
$green: #274120;
$white: #ffffff;
$bg-green: #214f2b;
$black: #000000;
$yellow: #F4C34D;
/**font family**/
$KB: 'Karbon-Bold';
$KBI: 'Karbon-BoldItalic';
$KHL: 'Karbon-Hairline';
$KHLI: 'Karbon-HairlineItalic';
$KL: 'Karbon-Light';
$KLI: 'Karbon-LightItalic';
$KM: 'Karbon-Medium';
$KMI: 'Karbon-MediumItalic';
$KR: 'Karbon-Regular';
$KRI: 'Karbon-RegularItalic';
$KSB: 'Karbon-Semibold';
$KSBI: 'Karbon-SemiboldItalic';
$KT: 'Karbon-Thin';
$KTI: 'Karbon-ThinItalic';
$Ar: 'GESS-Text-Light';

$SB: 'Surt-Bold';
$SR: 'Surt-Regular';

//font-sizes
$f40: 40px;
$f25: 25px;
$f19: 19px;
$f16: 16px;
$f14: 14px;
$f12: 12px;