.card-main-wrapper {
  .page-home & {
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 18px;
    transform: translate(-50%,0);
    width: 100%;
    @media (max-width: 710px) {
      bottom: 5%;
    }
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .not-front & {
      justify-content: flex-start;
      @media (max-width: 710px) {
        justify-content: space-between;
      }
    } 
    .page-home & {
       @media (max-width: 710px) {
      max-width: 338px;
      margin: auto;
      }
      
   
    }
    /* .not-front & {
        @media (max-width: 567px) {
          max-width: 338px;
          margin: auto;
        }
      } */
    > div {
      flex: 0 0 23%;
      max-width: 240px;
      @media (max-width: 710px) {
        flex: 0 0 48%;
        margin-bottom: 10px;
        max-width: 151px;
      }
      @media (max-width: 374px) {
        flex: 0 0 44%;
        max-width: calc(100% - 10px);
      }
      .not-front & {
        @media (min-width: 711px) and (max-width: 1023px) {
          flex: 0 0 22%;
          
        }
        @media (min-width: 711px) {
           &:nth-child(4n-2) {
           margin-left: 1%;
           margin-right: 1%;
          }
          &:nth-child(4n-1) {
            margin-right: 1%;
          }
        }
        @media (max-width: 710px) {
          flex: 0 0 45%;
          max-width: calc(100% - 10px);
        }
      }
    }
     a {
      text-decoration: none;
    }
    .card {

       background: $white;
       padding: 6px;
       border-radius: 35px;
       background: $white;
       .card--header.img-wrap {
         position: relative;
         div.vedio-icon {
           position: absolute;
           left: 50%;
           top: 50%;
           display: inline-block;
           transform: translate(-50%,-50%);
           img {
             width: 50px;
             height: 50px;
             object-fit: contain;
           }
         }
       }
       img {
        width: 100%;
        border-radius: 35px;
        height: 214px;
        object-fit: cover;
        @media (min-width: 711px) and (max-width: 1080px) {
          height: calc(149px + (214 - 149) * ((100vw - 711px) / (1080 - 711)));
        }
        @media (max-width: 710px) {
          height: calc(134px + (269 - 134) * ((100vw - 375px) / (710 - 375)));
        }
       }
       @media (max-width: 710px) {
         border-radius: 25px;
         img {
          border-radius: 25px;
         }
       }
       .card--footer {
         padding: 20px 0 25px;
         font-size: $f19;
         color: $green;
         .node-foodservice & {
           color: #2f2f2f;
         }
         .node-butchery & {
           color: #606060;
         }
         @media (max-width: 1920px) {
          font-size: calc(14px + (19 - 14) * ((100vw - 375px) / (1920 - 375)));
          padding: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375))) 0 calc(17px + (25 - 17) * ((100vw - 375px) / (1920 - 375)));
         }
         
         text-align: center;
         line-height: 1.2;
         
         font-family: $SR;
         b {
          font-family: $SB;
          .not-front & {
            line-height: 1.2;
          }
          
         }
       }

    }
    &.li-wrapper {
      > div {
         &:nth-of-type(1) {
           margin-right: 2.5%;
         }
      }
    }
  }
}

.bg-card-wrapper {
  background: $white;
  border-radius: 25px;
  background-clip: padding-box;
  padding: 3px;
  margin-bottom: 40px;
   @media (max-width: 1920px) {
        margin-bottom: calc(35px + (40 - 35) * ((100vw - 375px) / (1920 - 375)));
      }
  .bg-card-header {
    &.img-wrap {
      position: relative;
      .vedio-icon {
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        img {
          width: 91px;
          @media (max-width: 1920px) {
            width: calc(40px + (91 - 40) * ((100vw - 375px) / (1920 - 375)));
          }
        }
      }
    }
    picture img {
      border-radius: 25px;
      width: 100% !important;
      height: 330px;
      object-fit: cover;
      @media (max-width: 1920px) {
        height: calc(140px + (330 - 140) * ((100vw - 375px) / (1920 - 375)));
      }
      @media (max-width: 710px) {
        border-radius: 35px;
      }
    }
  }
  .bg-card-content {
    padding: 35px 45px;
    @media (max-width: 1920px) {
      padding: calc(25px + (35 - 25) * ((100vw - 375px) / (1920 - 375))) calc(17px + (45 - 17) * ((100vw - 375px) / (1920 - 375)));
    }
    .bg-card-body {
       h2 {
         color: $bg-green;
         .node-foodservice & {
           color: #2f2f2f;
         }
         .node-butchery &  {
           color: #541818;
         }
         .page-cuts-by-protien.node-beef & {
           color: #7f1718;
         }
          .page-cuts-by-protien.node-goat & {
           color: #ffc222;
         }
         font-size: 24px;
         @media (max-width: 1080px) {
           font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375))) 
          }
         margin-bottom: 20px;
         line-height: 1.2;
         font-family: $SB;
       }
       ul {
         list-style-type: initial;
         padding-left: 15px;
         li {
           padding-left: 16px;
           line-height: 1.5;
           &+ li {
             margin-top: 10px;
           }
         }
       }
       p {
        font-size: 18px;
         @media (max-width: 1080px) {
           font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
          }
          @media (max-width: 1080px) {
            br {
              display: none;
            }
          }
        font-family: $SR;
        line-height: 1.5;
        margin-bottom: 20px;
        .node-foodservice & {
          color: #2f2f2f;
          a {
            color: #2f2f2f;
            text-decoration: none;
          }
        }
       }
      .node-foodservice & {
        color: #2f2f2f;
      }
    }
    .bg-card-footer {
      a.action-btn {
          display: inline-block;
           padding: 9px 25px;
           background: #fec839;
           color: $green;
           font-family: $SB;
           border-radius: 25px;
           font-size: 18px;
           white-space: nowrap;
           margin-bottom: 20px;
           .node-foodservice & {
             background: #111111;
             color: $white;
           }
           @media (max-width: 1920px) {
            font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
           }
           
           text-decoration: none;
           
      }
    }
  }

  &.meatcuts-card {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    .bg-card-header {
      flex: 0 0 29%;
      
      picture {
        display: flex;
        height: 100%;
      }
      img {
        width: 100% !important;
        height: 100%;
        object-fit: cover;;
      }

      @media (max-width: 710px) {
        flex: 0 0 100%;
        img {
          height: calc(140px + (181 - 140) * ((100vw - 375px) / (710 - 375)));
           object-fit: cover;
           margin-bottom: 15px;
        }
      }
    }
    .bg-card-content {
      flex: 1;
      padding: 15px;
    }
    .bg-card-footer {
      a.action-btn {
        margin-bottom: 0;
      }
    }
  }

}
main {
  position: relative;
}

.meatcuts-card-container {
  padding-top: 45px;
  @media (max-width: 710px) {
    padding-top: 25px;
  }
}