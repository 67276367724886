.page-about,
.not-front {
  main {
    background: #f7f7f7;
  }
  h2.heading {
    color: #5c7b35;
    font-size: 26px;
 
    @media (max-width: 1080px) {
     font-size: calc(16px + (26 - 16) * ((100vw - 375px) / (1080 - 375)));
      br {
        display: none;
      }
    }
    margin-bottom: 20px;
    line-height: 1.5;
    font-family: $SR; 
    @media (max-width: 1080px) {
      padding-right: 15px;
    }
  }
}

.not-front.node-foodservice {
  main {
    background: #fbf9ec;
  }
  h2.heading {
  color: #2f2f2f;
 }   
}

.not-front.node-butchery {
  main {
    background: #f2f2f2;
  }
  h2.heading {
    color: #606060;
  }
}

.page-cuts-by-protien.node-beef.not-front h2.heading {
  color: #7f1718;
}
.page-cuts-by-protien.node-goat.not-front h2.heading {
  color: #ffc222;
}