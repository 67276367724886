.node-foodservice.not-front {
  .loadmore button {
    color: #2f2f2f;
  }
  &.page-foodservice-recipies {
    .card-main-wrapper .card-wrapper
    {
      .card {
        display: none;
        &:nth-child(-n+8) {
          display: block;
        }
      .card--footer {
       b {
          span {
           text-transform: uppercase !important;
         }
       }
    } 

    }
    } 

  }
}