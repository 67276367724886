footer {
  .page-home & {
    margin-top: -10px;
  }
  
  .f-top {
    background: $bg-green;
    color: $white;
    padding: 30px 0 40px;
    .node-foodservice & {
      background: #111111;
    }
    .node-butchery & {
      background: #541818;
    }
    @media (min-height: 1199px) {
      padding: 4% 0;
    }
  
    * {
      color: inherit;
      text-decoration: none;
    }
    .f-social--wrapper {
      display: none;
      @media (max-width: 710px) {
        display: block;
        .f-social--links {
          display: flex;
          align-items: center;
          justify-content: center;
          li {
            &+ li {
              margin-left: 10px;
            }
            a {
            font-size: 0;
            width: 30px;
            height: 30px;
            display: inline-block;
            background-image: url("../web-images/socialMobile.png");
            .node-foodservice &,
            .node-butchery & {
              background-image: url("../web-images/socialMobile-white.png");
            }
            background-repeat: no-repeat;
            &.tw {
              background-position: -44px 0;
            }
            &.in {
              background-position: -88px 0;
            }
          }
          }
        }
      }
    }
    .f-top--wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 710px) {
        > div {
         flex: 0 0 100%;
         text-align: center;
         &+  div {
           padding-top: 10px;
         }
       }
     }

     p {
       padding-bottom: 10px;
       font-size: $f19;
       line-height: 1;
       @media (max-width: 1920px) {
         font-size: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
       }
       font-family: $SB;
       border-bottom: 2px dotted #51744A;
       pointer-events: none;
       .node-foodservice & {
         border-color: #888888 !important;
        }
        .node-butchery & {
          border-color: #aa8c8c !important;
        }
        a {
          pointer-events: initial !important;
        }
       @media (max-width: 710px) {
        pointer-events: initial;
        border-bottom: 1px solid #51744A;
        margin-bottom: 0;
        padding-bottom: 10px;

      }
      @media (min-width: 711px) {
        display: inline-block;
      }
    }
    ul {

      li {
        margin-top: 10px;
        width: 100%;

        a {
         font-size: $f16;
         @media (max-width: 1920px) {
          font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
         }
        font-family: $SR;
        line-height: 1;
      }
    }
  }
  .f-top--item {
    
    @media (max-width: 710px) {
      p {
        cursor: pointer;
        position: relative;
        z-index: 0;
        &:before,&:after {
          content: "";
          width: 15px;
          height: 1px;
          background: #c6c6c6;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: -1;
          display: inline-block;

        }
        &:after {
          transform: rotate(90deg);
        }
      }
      ul {
          transition: all ease 0.45s;
        }
      &.close {

        ul {
         height: 0;
         overflow: hidden;

       }
     }
     &.open {
      p {
        border-bottom: none;
        &:after {
          display: none;
        }
      }
      ul {
        height: auto;
        overflow: visible;
        border-bottom: 1px solid #51744A;
        padding-bottom: 10px; 
       .node-foodservice & {
         border-color: #888888 !important;
        }
        .node-butchery & {
          border-color: #aa8c8c !important;
        }        
      }
    }
  }
}

}
}
.f-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  @media (max-width: 600px) {
    padding: 15px 0 52px;
  }
  > div ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .f-bottom-menu {
    @media (max-width: 600px) {
      width: 100%;
    }


    ul {
      width: 474px;
      justify-content: space-between;
      @media (max-width: 600px) {
        width: 100%;
        flex: 0 0 100%;
        margin-top: 10px;
      }
      @media (min-width: 601px) and (max-width: 769px) {
        width: 350px;
      }
      a {
        color: $bg-green;
       .node-foodservice &,
       .node-butchery & {
         color: #606060;
       }
        text-decoration: none;
        font-family: $SR;
        font-size: $f14;
        @media (max-width: 1920) {
          font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
        }
        
      }
    }
  }
  .app-store {
    @media (max-width: 600px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    ul {
     li {
       font-size: $f16;
       font-family: $SR;
       @media (max-width: 710px) {
        font-family: $SB;
       }
       &:nth-last-of-type(1) {
        position: relative;
        top: 1px;
       }
       color: $bg-green;
       .node-foodservice &,
       .node-butchery &  {
         color: #606060;
       }
       
       a {
         font-size: 0;
         display: inline-block;
         vertical-align: top;
         width: 25px;
         height: 25px;
         background-image: url("../web-images/app-store.png");
         .node-foodservice & {
           background-image: url("../web-images/app-store-black.png");
         }
         .node-butchery & {
           background-image: url("../web-images/app-store-brown.png");;
         }
         background-repeat: no-repeat;
         &.play-store {
           background-position: -30px 0;
         }
       }
       &+ li {
         margin-left: 10px;
       }
     }
   }
 }

}
}