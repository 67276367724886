.link-tabs {
  ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      background: #eeeeee;
      border-radius: 20px;
      max-width: max-content;
      .page-cooking-methods & {
          @media (max-width: 439px) {
            background: none;
          }
      }
     
      li {
        a {
          text-decoration: none;
          background: #eeeeee;
          color: $black;
          font-family: $SB;
           font-size: 20px;
           line-height: 1;
           padding: 10px 20px;
           display: inline-block;
           cursor: pointer;
           @media (max-width: 1920px) {
             font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
           }

           position: relative;
           z-index: 1;
           
        }
        &:nth-of-type(1) {
          a {
            border-radius: 20px 0 0 20px;
            &:before {
              display: none;
            }
          }
        }
        &:nth-last-of-type(1) {
          a {
            border-radius: 0 20px 20px 0;
            &:after {
              display: none;
            }
          }
        }
        a.active-link,a.active {
          // background: $green;
          background: #556b2a;
          border-radius: 20px;
          color: $white;
          .page-know-your-cuts & {
            background-color: #541818;
          }  
          .page-cuts-by-protien.node-beef & {
              background-color: #7f1718;
            }
            .page-cuts-by-protien.node-goat & {
              background-color: #ffc222;
            }
            
        }
        .page-cooking-methods & {
           a {
             @media (max-width: 500px) {
              display: inline-block;
              &:before,&:after {
                display: none;
              }
              border-radius: 20px;
             }
           }
        }
      }
  }

}

.b-tab {
  padding: 0;
  border: none;
  display: block;
  height: 0;
  overflow: hidden;
  &.active {
    height: auto;
    padding: 20px;
  }
}



@-webkit-keyframes fadeinout {
  0% { opacity: 0;height: 0; }
  50%,100% { opacity: 1;height: auto; }
}

@keyframes fadeinout {
  0% { opacity: 0;height: 0; }
  50%,100% { opacity: 1; height: auto; }
}