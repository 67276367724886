.page-search.not-front {
  .banner-container {
    margin-bottom: 90px;
    @media (max-width: 1920px) {
      margin-bottom: calc(20px + (90 - 20) * (100vw - 375px) / (1920 - 375));
    }
  }
  .t-heading {
    margin-bottom: 0;
    color: #214f2b;
  }
}