* { margin:0; padding:0; }
h1,h2,h3 { font-size: 100%; font-weight: normal; }
ol,ul { list-style: none; }
img {border: 0;max-width: 100%;}
html {min-height: 100%;height: 100%; scroll-behavior: smooth;}
body {
  height: 100%;
  font-size: 18px;
  @media (max-width: 1080px) {
     font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
  width: 100%;
  margin: auto;
  font-family: $SR;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smooth: antialiased;
}

.container {
  width: 100%;
  max-width: 1030px;
  margin: auto;
  @media (max-width: 1120px) {
    max-width: calc(100% - 40px);
  }
}
@media (min-width: 1024px) and (-webkit-device-pixel-ratio: 1.25) {
  body  {
    zoom: 0.8;
  }
  .container {
    max-width: 1030px;
  }
}

.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.align-start {
  align-self: flex-start;
}
.align-center {
  align-self: center;
}

.align-bottom {
  align-self: flex-end;
}

.content-bg-fff {
  background: $white;
  padding: 30px 35px;
  border-radius: 30px;
  background-clip: padding-box;
  @media (max-width: 1080px) {
     padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(20px + (35 - 20) * ((100vw - 375px) / (1080 - 375)));
  }
  p {
    line-height: 1.7;
  }
}

main {
 min-height: 710px;
}

a[title='logo'] {
  text-decoration: none;
  &:hover,&:focus {
    text-decoration: none;
    outline: none;
    border: 0;
  }
}

.pt-20 {
  padding-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-20 {
  padding-bottom: 20px;
}

.pt-45 {
  padding-top: 45px;
  @media (max-width: 439px) {
    padding-top: 20px;
  }
}

ul.li-pr-16 {
 li {
   padding-right: 16px;
 }
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.pb-70 {
  padding-bottom: 70px;
  @media (max-width: 710px) {
    padding-bottom: 40px;
  }
}
.highlighted {
  padding: 34px 50px;
  border-radius: 25px;
  background-clip: padding-box;
  margin-bottom: 20px;
  @media (max-width: 710px) {
    padding: 20px 30px;
  }
  background: #111;
  * {
    color: #fbf9ec;
  }
  h2 {
    font-size: 24px;
    line-height: 1.5;
    font-family: $SB;
    margin-bottom: 15px;
    @media (max-width: 1080px) {
    font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375)));
    }
  }
  p {
    line-height: 1.5;
    a {
      text-decoration: none;
    }
  }
}
.t-heading {
  font-size: 24px;
  line-height: 1.5;
  font-family: $SB;
  color: #7f1718;
  margin-bottom: 30px;
  .node-foodservice & {
    color: #2f2f2f;
  }
  &.related-search,
  &.dg-txt-colr,
  .page-cuts-by-protien.node-lamb &,
  .page-resources & {
    color: #274120;
  }
  .page-resources & {
    margin-bottom: 0;
  }
  .page-cuts-by-protien.node-goat & {
    color: #ffc222;
  }
  .card-main-wrapper & {
    .page-cuts-by-protien.node-beef &,
    .page-cuts-by-protien.node-goat & {
    color: #274120;
    }
  }
  @media (max-width: 1080px) {
    font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}

.lh-150 {
  line-height: 1.5;
}

.ol-list-wrapper {
  ul {
    counter-reset: item;
    margin-bottom: 20px;
    > b {
      width: 100%;
      display: inline-block;
      margin-bottom: 20px;
    }
    li {
      counter-increment: item;
      position: relative;
      padding-left: 30px;
      font-size: 18px;
      line-height: 1.5;
      @media (max-width: 1080px) {
        font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
      }
      &+ li {
        margin-top: 30px;
      }
      &:before {
        content: counter(item);
        width: 20px;
        height: 20px;
        background: #757575;
        font-size: 14px;
        display: flex;
        left: 0;
        top: 4px;
        position: absolute;
        justify-content: center;
        align-items: flex-end;
        border-radius: 50%;
        color: $white;
        font-family: $SR;

      }
    }
  }
}

.ul-list-wrapper {
  ul {
    li {
      position: relative;
      padding-left: 30px;
      &+ li {
        margin-top: 30px;
      }
      &:before {
        content: "";
        width: 5px;
        height: 5px;
        background: $green;
        border-radius: 5px;
        position: absolute;
        display: inline-block;
        left: 0;
        top: 5px;
      }
    }
    &.lh-0 {
      li + li {
        margin-top: 0;
      }
    }
  }
}

.about-recipie {
  margin-bottom: 20px;
  > div {
    &:nth-child(2n) {
      padding-left: 30px;
      padding-right: 30px;
      position: relative;
      &:before,&:after {
        content: "";
        width: 1px;
        height: calc(100% - 15px);
        background: #5c5c5c;
        position: absolute;
        display: inline-block;  
      }
      &:before {
        left: 15px;
        top: 4px;
      }
      &:after {
        right: 15px;
        top: 4px;
      }
    }
  }
  .title {
    color: rgba(0,0,0,0.5);
    font-size: 18px;
    font-family: $SR;
    margin-bottom: 10px;
    color: #5c5c5c;
    white-space: nowrap;
    @media (max-width: 1080px) {
      font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
    }
  }
  .value {
    color: #fec223;
    font-size: 35px;
    font-family: $KSB;
    text-align: center;
    @media (max-width: 1080px) {
      font-size: calc(22px + (35 - 22) * ((100vw - 375px) / (1080 - 375))) 
    }
  }
}

.social-share-links {
  p {
    color: #5c5c5c;
    font-size: 18px;
    font-family: $SR;
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: 1080px) {
      font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
    }
  }
  .jssocials {
    .jssocials-shares {
       display: flex;
       align-items: center;
       justify-content: center;
       
       > div {
         width: 30px;
         height: 30px;
         position: relative;
         &+ div {
            margin-left: 5px;
         }
         a {
          display: inline-block;
          width: 100%;
          height: 100%;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          background: url("../web-images/social-share-icon-group.png") no-repeat;
         }
         &.jssocials-share-email a {
           background-position: 0 0;
         }
         &.jssocials-share-facebook a {
           background-position: -35px 0;
         }
         &.jssocials-share-twitter a {
           background-position: -70px 0;
         }
         &.jssocials-share-linkedin a {
           background-position: -106px 0;
         }
         &.jssocials-share-whatsapp a {
          background-position: -141px 0;
         }
       }
    }
  }
}

b,strong {
  font-family: $SB;
}

:focus-visible {
  outline: none;
}

.loadmore {
  button {
    position: relative;
   width: auto;
   height: 40px;
   line-height: 40px;
   font-size: 18px;
   font-family: $SB;
   color: $green;
   border: none;
   cursor: pointer;
   background: transparent;
   border-radius: 55px;
   transition: all ease 0.45s;
   padding-right: 30px;
   @media (max-width: 1920px) {
    font-size: calc(14px + (18 - 14) * ((100vw - 375px) / (1920 - 375)));
   }
   span {
        font-size: 0;
        width: 1px;
        height: 16px;
        background: $green;
        position: relative;
        display: inline-block;
        left: 10px;
        top: 7px;
        @media (max-width: 1920px) {
          height: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
          
         }
        &+ span {
           transform: rotate(90deg);
           left: 9px;
        }
  }
    }
}

.captcha-container {
  display: inline-block;
  margin-bottom: 20px;
  .captcha {
    display: flex;
    button {
      align-items: center;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 20px;
      padding: 0 10px;
      height: 36px;
      font-weight: bold;
      background:#274120;
      color: #ffffff;
      margin-left: 5px;
    }
    
  }
  input[type="text"] {
    width: 129px;
    height: 15px;
    padding: 5px 18px;
    border: none;
    background: #f7f7f7;
    border-radius: 15px;
  }
}

.lambandbeef-app-stores {
  align-items: flex-end;
  > div {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    @media (max-width: 450px) {
      flex: 0 0 100%;
    }
  }
  a {
   text-decoration: none;
  }
}
.image-map-wrapper {
  text-align: center;
  padding: 50px 0;
  @media (max-width: 1080px) {
    padding: calc(20px + (50 - 20) * ((100vw - 375px)/(1080 - 375)))
  }
}

p.btn-wrap {
  a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    color: #541818;
    background-color: #ffc222;
    padding: 7px 15px;
    border-radius: 20px;
    background-clip: padding-box;
    white-space: nowrap;
    font-family: $SB;
    margin-top: 20px;

  }
}

.page-know-your-cuts {
  .d-image-map {
    display: block;
    @media (max-width: 710px) {
      display: none;
    }
  }
  .m-image-map {
    display: none;
    @media (max-width: 710px) {
      display: block;
    }
  }
}
map area {
  cursor: pointer;
}