section.cart-wrapper {
  position: fixed;
  right: 0;
  @media (min-width: 711px) {
    top: 40%;
    &.stt-intersect {
      top: 20%;
    }
  }
  @media (max-width: 710px) {
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }
  a {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 25px 35px 25px 45px;
    font-size: $f16;
    font-family: $SR;
    text-decoration: none;
    color: $white;
    background: $green;
    .node-foodservice & {
      background: #111111;
    }
    .node-butchery & {
      background: #541818;
    }
    border-radius: 25px 0 0 25px;
    position: relative;
    @media (min-width: 711px) {
      width: 79px;
      padding: 20px 35px 16px 25px;
    }
    @media (max-width: 710px) {
      padding: 7px 20%;
      justify-content: center;
      align-items: center;
      border-radius: 30px 30px 0 0;
    }
    &:before {
      content: "";
      width: 1px;
      height: 60%;
      border-left: 2px dotted #51744A;
      display: inline-block;
      position: absolute;
      left: 22px;
      .node-foodservice & {
        border-color: #888 !important;
      }
      .node-butchery & {
        border-color: #aa8c8c !important;
      }
      @media (max-width: 710px) {
        display: none;
      }
    }
   span {
       display: inline-block;
       width: 60px;
       line-height: 1.2;
       @media (max-width: 710px) {
        width: auto;
       }
       @media (min-width: 711px) {
        padding-left: 20px;
       }
    }
    svg {
      width: 60px;
      height: 28px;
      fill: #f4c34d;
      .node-foodservice & {
        fill: #eff9f1;
      }
      margin-bottom: 10px;
    }
    img {
      flex: 0 0 100%;
      height: 28px;
      object-fit: contain;
      object-position: left;
      margin-bottom: 10px;
      @media (min-width: 711px) {
        margin-left: 20px;
      }
      @media (max-width: 710px) {
        flex: 0 0 auto;
        margin-bottom: 0;
        padding-left: 20px;
        border-left: 2px dotted #51744A;
        margin-right: 10px;
      }
    }

  }
}