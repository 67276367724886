
#map {
  border-radius: 35px;
}

.slick-slider {
  padding-bottom: 75px;
  position: relative;
  .retailer {
      background: $white;
      padding: 5px 5px 10px 5px;
      border-radius: 35px;
      margin-top: 25px;
      .retailer-logo {
        img {
          border-radius: 35px;
        }
      }
      .buy-online {
        padding-top: 14px;
        padding-bottom: 7px;
        text-align: center;
        min-height: 10px;
        a {
           display: inline-block;
           text-decoration: none;
           cursor: pointer;
           padding: 7px 25px;
           font-family: $SB;
           border-radius: 18px;
           border: 2px solid #eeeeee;
           color: #274120;
        }
      }
      .store-links {
        display: flex;
        align-items: center;
        justify-content: center;
        a + a {
          margin-left: 10px;
        }
      }
    }
    .slick-arrow {
      position: absolute;
      bottom: 30px;
      cursor: pointer;
      width:  16px;
      height: 16px;
      overflow: hidden;
      background: transparent;
      border: none;
      color: transparent;
      background: url("../web-images/where-to-buy/next-pre-buttons.png");
      outline: none;
      box-shadow: none;
      &.slick-prev {
        left: calc(50% - 16px);
      }
      &.slick-next {
        left: calc(50% + 16px);
        background-position: 16px 0;
      }
    }
}

.retailers-tablist {
  margin-bottom: 10px;
}

.page-where-to-buy {
  main {
    padding-bottom: 70px;
  }
  .b-tab.active {
    padding: 0;
  }
  .slick-slide {
     > div {
        display: flex;
        @media (max-width: 710px) {
          justify-content: space-between;
        }
         > div {
            @media (min-width: 711px) {
              flex: 0 0 23%;
              max-width: 240px;
              &:nth-child(4n - 2) {
                margin-left: 1%;
                margin-right: 1%;
              }
              &:nth-child(4n - 1) {
                margin-right: 1%;
              }
            }

            @media (max-width: 710px) {
              flex: 0 0 45%;
              max-width: calc(100% - 10px);
              .retailer-logo img {
                width: 100%;
                object-fit: cover;
                height: calc(134px + (269 - 134) * ((100vw - 375px) / (710 - 375)));
              }
            }
         }
     }
  }
}