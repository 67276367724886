.tags-wrapper {
  .tags-title {
    color: #5c5c5c;
    font-size: 14px;
    font-family: $SR;
    margin-bottom: 10px;
    display: none;
  }
   .tags-list {
     ul {
       li {
         margin-right: 10px;
         &:nth-last-of-type(1) {
          margin-right: 0;
         }
        a {
           text-decoration: none;
           font-family: $SB;
           font-size: 18px;
           color: #5c5c5c;
           padding: 10px 15px;
           background: $white;
           border-radius: 18px;
           display: inline-block;
           margin-bottom: 10px;
           @media (max-width: 1080px) {
            font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
          }
          &.active-link {
            background-color: #274120;
            color: #fff;
            .page-cuts-by-protien.node-beef & {
              background-color: #7f1718;
            }
          }
           
        }
       }
     }
   }
}