@charset "UTF-8";
/**colors***/
/**font family**/
@font-face {
  font-family: "Karbon-Bold";
  src: url("../fonts/Karbon/Karbon-Bold.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Bold.woff") format("woff"), url("../fonts/Karbon/Karbon-Bold.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Bold.otf") format("opentype"), url("../fonts/Karbon/Karbon-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-BoldItalic";
  src: url("../fonts/Karbon/Karbon-BoldItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-BoldItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-BoldItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-BoldItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-BoldItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Hairline";
  src: url("../fonts/Karbon/Karbon-Hairline.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Hairline.woff") format("woff"), url("../fonts/Karbon/Karbon-Hairline.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Hairline.otf") format("opentype"), url("../fonts/Karbon/Karbon-Hairline.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-HairlineItalic";
  src: url("../fonts/Karbon/Karbon-HairlineItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-HairlineItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-HairlineItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-HairlineItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-HairlineItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Light";
  src: url("../fonts/Karbon/Karbon-Light.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Light.woff") format("woff"), url("../fonts/Karbon/Karbon-Light.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Light.otf") format("opentype"), url("../fonts/Karbon/Karbon-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-LightItalic";
  src: url("../fonts/Karbon/Karbon-LightItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-LightItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-LightItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-LightItalic.otf") format("opentype") url("../fonts/Karbon/Karbon-LightItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Medium";
  src: url("../fonts/Karbon/Karbon-Medium.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Medium.woff") format("woff"), url("../fonts/Karbon/Karbon-Medium.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Medium.otf") format("opentype"), url("../fonts/Karbon/Karbon-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-MediumItalic";
  src: url("../fonts/Karbon/Karbon-MediumItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-MediumItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-MediumItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-MediumItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-MediumItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Regular";
  src: url("../fonts/Karbon/Karbon-Regular.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Regular.woff") format("woff"), url("../fonts/Karbon/Karbon-Regular.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Regular.otf") format("opentype"), url("../fonts/Karbon/Karbon-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-RegularItalic";
  src: url("../fonts/Karbon/Karbon-RegularItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-RegularItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-RegularItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-RegularItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-RegularItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Semibold";
  src: url("../fonts/Karbon/Karbon-Semibold.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Semibold.woff") format("woff"), url("../fonts/Karbon/Karbon-Semibold.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Semibold.otf") format("opentype"), url("../fonts/Karbon/Karbon-Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-SemiboldItalic";
  src: url("../fonts/Karbon/Karbon-SemiboldItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-SemiboldItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-SemiboldItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-SemiboldItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-SemiboldItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-Thin";
  src: url("../fonts/Karbon/Karbon-Thin.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-Thin.woff") format("woff"), url("../fonts/Karbon/Karbon-Thin.ttf") format("truetype"), url("../fonts/Karbon/Karbon-Thin.otf") format("opentype"), url("../fonts/Karbon/Karbon-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karbon-ThinItalic";
  src: url("../fonts/Karbon/Karbon-ThinItalic.eot");
  src: local("☺"), url("../fonts/Karbon/Karbon-ThinItalic.woff") format("woff"), url("../fonts/Karbon/Karbon-ThinItalic.ttf") format("truetype"), url("../fonts/Karbon/Karbon-ThinItalic.otf") format("opentype"), url("../fonts/Karbon/Karbon-ThinItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Surt-Bold";
  src: url("../fonts/Surt-Bold/ATSurt-Bold.eot");
  src: local("☺"), url("../fonts/Surt-Bold/ATSurt-Bold.woff") format("woff"), url("../fonts/Surt-Bold/ATSurt-Bold.ttf") format("truetype"), url("../fonts/Surt-Bold/ATSurt-Bold.otf") format("opentype"), url("../fonts/Surt-Bold/ATSurt-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Surt-Regular";
  src: url("../fonts/Surt-Regular/ATSurt-Regular.eot");
  src: local("☺"), url("../fonts/Surt-Regular/ATSurt-Regular.woff") format("woff"), url("../fonts/Surt-Regular/ATSurt-Regular.ttf") format("truetype"), url("../fonts/Surt-Regular/ATSurt-Regular.otf") format("opentype"), url("../fonts/Surt-Regular/ATSurt-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica-Nueue-Arabic-Roman";
  src: url("../fonts/HelveticaNeueLT Arabic 55 Roman.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GESS-Text-Light";
  src: url("../fonts/GessLight/GESSTextLight.eot");
  src: local("☺"), url("../fonts/GessLight/GESSTextLight.woff") format("woff"), url("../fonts/GessLight/GESSTextLight.ttf") format("truetype"), url("../fonts/GessLight/GESSTextLight.otf") format("opentype"), url("../fonts/GessLight/GESSTextLight.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-size: 100%;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  border: 0;
  max-width: 100%;
}

html {
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-size: 18px;
  width: 100%;
  margin: auto;
  font-family: "Surt-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smooth: antialiased;
}
@media (max-width: 1080px) {
  body {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}

.container {
  width: 100%;
  max-width: 1030px;
  margin: auto;
}
@media (max-width: 1120px) {
  .container {
    max-width: calc(100% - 40px);
  }
}

@media (min-width: 1024px) and (-webkit-device-pixel-ratio: 1.25) {
  body {
    zoom: 0.8;
  }

  .container {
    max-width: 1030px;
  }
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.align-start {
  align-self: flex-start;
}

.align-center {
  align-self: center;
}

.align-bottom {
  align-self: flex-end;
}

.content-bg-fff {
  background: #ffffff;
  padding: 30px 35px;
  border-radius: 30px;
  background-clip: padding-box;
}
@media (max-width: 1080px) {
  .content-bg-fff {
    padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(20px + (35 - 20) * ((100vw - 375px) / (1080 - 375)));
  }
}
.content-bg-fff p {
  line-height: 1.7;
}

main {
  min-height: 710px;
}

a[title=logo] {
  text-decoration: none;
}
a[title=logo]:hover, a[title=logo]:focus {
  text-decoration: none;
  outline: none;
  border: 0;
}

.pt-20 {
  padding-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-45 {
  padding-top: 45px;
}
@media (max-width: 439px) {
  .pt-45 {
    padding-top: 20px;
  }
}

ul.li-pr-16 li {
  padding-right: 16px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}
@media (max-width: 710px) {
  .pb-70 {
    padding-bottom: 40px;
  }
}

.highlighted {
  padding: 34px 50px;
  border-radius: 25px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background: #111;
}
@media (max-width: 710px) {
  .highlighted {
    padding: 20px 30px;
  }
}
.highlighted * {
  color: #fbf9ec;
}
.highlighted h2 {
  font-size: 24px;
  line-height: 1.5;
  font-family: "Surt-Bold";
  margin-bottom: 15px;
}
@media (max-width: 1080px) {
  .highlighted h2 {
    font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.highlighted p {
  line-height: 1.5;
}
.highlighted p a {
  text-decoration: none;
}

.t-heading {
  font-size: 24px;
  line-height: 1.5;
  font-family: "Surt-Bold";
  color: #7f1718;
  margin-bottom: 30px;
}
.node-foodservice .t-heading {
  color: #2f2f2f;
}
.t-heading.related-search, .t-heading.dg-txt-colr, .page-cuts-by-protien.node-lamb .t-heading, .page-resources .t-heading {
  color: #274120;
}
.page-resources .t-heading {
  margin-bottom: 0;
}
.page-cuts-by-protien.node-goat .t-heading {
  color: #ffc222;
}
.page-cuts-by-protien.node-beef .card-main-wrapper .t-heading, .page-cuts-by-protien.node-goat .card-main-wrapper .t-heading {
  color: #274120;
}
@media (max-width: 1080px) {
  .t-heading {
    font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}

.lh-150 {
  line-height: 1.5;
}

.ol-list-wrapper ul {
  counter-reset: item;
  margin-bottom: 20px;
}
.ol-list-wrapper ul > b {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}
.ol-list-wrapper ul li {
  counter-increment: item;
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  line-height: 1.5;
}
@media (max-width: 1080px) {
  .ol-list-wrapper ul li {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.ol-list-wrapper ul li + li {
  margin-top: 30px;
}
.ol-list-wrapper ul li:before {
  content: counter(item);
  width: 20px;
  height: 20px;
  background: #757575;
  font-size: 14px;
  display: flex;
  left: 0;
  top: 4px;
  position: absolute;
  justify-content: center;
  align-items: flex-end;
  border-radius: 50%;
  color: #ffffff;
  font-family: "Surt-Regular";
}

.ul-list-wrapper ul li {
  position: relative;
  padding-left: 30px;
}
.ul-list-wrapper ul li + li {
  margin-top: 30px;
}
.ul-list-wrapper ul li:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #274120;
  border-radius: 5px;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 5px;
}
.ul-list-wrapper ul.lh-0 li + li {
  margin-top: 0;
}

.about-recipie {
  margin-bottom: 20px;
}
.about-recipie > div:nth-child(2n) {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}
.about-recipie > div:nth-child(2n):before, .about-recipie > div:nth-child(2n):after {
  content: "";
  width: 1px;
  height: calc(100% - 15px);
  background: #5c5c5c;
  position: absolute;
  display: inline-block;
}
.about-recipie > div:nth-child(2n):before {
  left: 15px;
  top: 4px;
}
.about-recipie > div:nth-child(2n):after {
  right: 15px;
  top: 4px;
}
.about-recipie .title {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: "Surt-Regular";
  margin-bottom: 10px;
  color: #5c5c5c;
  white-space: nowrap;
}
@media (max-width: 1080px) {
  .about-recipie .title {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.about-recipie .value {
  color: #fec223;
  font-size: 35px;
  font-family: "Karbon-Semibold";
  text-align: center;
}
@media (max-width: 1080px) {
  .about-recipie .value {
    font-size: calc(22px + (35 - 22) * ((100vw - 375px) / (1080 - 375)));
  }
}

.social-share-links p {
  color: #5c5c5c;
  font-size: 18px;
  font-family: "Surt-Regular";
  margin-bottom: 15px;
  text-align: center;
}
@media (max-width: 1080px) {
  .social-share-links p {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.social-share-links .jssocials .jssocials-shares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-share-links .jssocials .jssocials-shares > div {
  width: 30px;
  height: 30px;
  position: relative;
}
.social-share-links .jssocials .jssocials-shares > div + div {
  margin-left: 5px;
}
.social-share-links .jssocials .jssocials-shares > div a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  background: url("../web-images/social-share-icon-group.png") no-repeat;
}
.social-share-links .jssocials .jssocials-shares > div.jssocials-share-email a {
  background-position: 0 0;
}
.social-share-links .jssocials .jssocials-shares > div.jssocials-share-facebook a {
  background-position: -35px 0;
}
.social-share-links .jssocials .jssocials-shares > div.jssocials-share-twitter a {
  background-position: -70px 0;
}
.social-share-links .jssocials .jssocials-shares > div.jssocials-share-linkedin a {
  background-position: -106px 0;
}
.social-share-links .jssocials .jssocials-shares > div.jssocials-share-whatsapp a {
  background-position: -141px 0;
}

b, strong {
  font-family: "Surt-Bold";
}

:focus-visible {
  outline: none;
}

.loadmore button {
  position: relative;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: "Surt-Bold";
  color: #274120;
  border: none;
  cursor: pointer;
  background: transparent;
  border-radius: 55px;
  transition: all ease 0.45s;
  padding-right: 30px;
}
@media (max-width: 1920px) {
  .loadmore button {
    font-size: calc(14px + (18 - 14) * ((100vw - 375px) / (1920 - 375)));
  }
}
.loadmore button span {
  font-size: 0;
  width: 1px;
  height: 16px;
  background: #274120;
  position: relative;
  display: inline-block;
  left: 10px;
  top: 7px;
}
@media (max-width: 1920px) {
  .loadmore button span {
    height: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  }
}
.loadmore button span + span {
  transform: rotate(90deg);
  left: 9px;
}

.captcha-container {
  display: inline-block;
  margin-bottom: 20px;
}
.captcha-container .captcha {
  display: flex;
}
.captcha-container .captcha button {
  align-items: center;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 20px;
  padding: 0 10px;
  height: 36px;
  font-weight: bold;
  background: #274120;
  color: #ffffff;
  margin-left: 5px;
}
.captcha-container input[type=text] {
  width: 129px;
  height: 15px;
  padding: 5px 18px;
  border: none;
  background: #f7f7f7;
  border-radius: 15px;
}

.lambandbeef-app-stores {
  align-items: flex-end;
}
.lambandbeef-app-stores > div {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
@media (max-width: 450px) {
  .lambandbeef-app-stores > div {
    flex: 0 0 100%;
  }
}
.lambandbeef-app-stores a {
  text-decoration: none;
}

.image-map-wrapper {
  text-align: center;
  padding: 50px 0;
}
@media (max-width: 1080px) {
  .image-map-wrapper {
    padding: calc(20px + (50 - 20) * ((100vw - 375px)/(1080 - 375)));
  }
}

p.btn-wrap a {
  display: inline-block;
  text-decoration: none;
  outline: none;
  color: #541818;
  background-color: #ffc222;
  padding: 7px 15px;
  border-radius: 20px;
  background-clip: padding-box;
  white-space: nowrap;
  font-family: "Surt-Bold";
  margin-top: 20px;
}

.page-know-your-cuts .d-image-map {
  display: block;
}
@media (max-width: 710px) {
  .page-know-your-cuts .d-image-map {
    display: none;
  }
}
.page-know-your-cuts .m-image-map {
  display: none;
}
@media (max-width: 710px) {
  .page-know-your-cuts .m-image-map {
    display: block;
  }
}

map area {
  cursor: pointer;
}

@media (max-width: 710px) {
  .top-header {
    display: none;
  }
}
.top-header .top-header--links {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  z-index: 100;
  height: 32px;
}
.top-header .top-header--links li + li {
  margin-left: 8px;
}
.top-header .top-header--links li a {
  font-size: 0;
  width: 18px;
  height: 18px;
  color: #919191;
  text-decoration: none;
  display: inline-block;
  background-image: url("../web-images/icons.png");
  background-repeat: no-repeat;
}
.top-header .top-header--links li a.fb {
  background-position: 0 0;
}
.top-header .top-header--links li a.tw {
  background-position: -24px 0;
}
.top-header .top-header--links li a.in {
  background-position: -48px 0;
}
.top-header .top-header--links li.togglelang a {
  font-family: "GESS-Text-Light";
  font-size: 19px;
  width: auto;
  height: auto;
  background-image: none !important;
}
body.open-navigation .top-header {
  background: #ffffff;
  position: relative;
  z-index: 100;
}

header {
  position: relative;
  background: none;
  transition: all ease 0.5s;
  z-index: 100;
}
header .mob-header {
  display: none;
}
@media (max-width: 710px) {
  header .mob-header {
    display: block;
  }
  header .mob-header ul {
    align-items: center;
  }
  header .mob-header li a {
    color: #919191;
    text-decoration: none;
    display: inline-block;
    margin-right: 18px;
  }
  header .mob-header .search-button button {
    background: none;
    border: none;
    box-shadow: none;
    margin-right: 18px;
    margin-top: 5px;
  }
  header .mob-header .search-button button:focus {
    outline: none;
  }
}
header.menu-open {
  background: #ffffff;
}
header .main-navigation--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 710px) {
  header .main-navigation--wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
header .main-navigation--wrapper .logo img {
  max-width: auto;
  width: 300px;
  margin-right: 5px;
}
@media (max-width: 1920px) {
  header .main-navigation--wrapper .logo img {
    width: calc(157px + (300 - 157) * (100vw - 375px)/(1920 - 375));
  }
}
@media (max-width: 374px) {
  header .main-navigation--wrapper .logo img {
    width: 157px;
  }
}
header .main-navigation--wrapper .search-form {
  position: relative;
}
header .main-navigation--wrapper .search-form:before {
  content: "";
  width: 17px;
  height: 17px;
  background-image: url("../web-images/search.png");
  background-repeat: no-repeat;
  position: absolute;
  left: 15px;
  top: 11px;
  display: inline-block;
}
header .main-navigation--wrapper .search-form input {
  width: 408px;
  height: 40px;
  line-height: 40px;
  background: #dadada;
  border-radius: 40px;
  border: none;
  padding: 0 20px 0 40px;
  color: #ffffff;
  font-size: 19px;
}
header .main-navigation--wrapper .search-form input::placeholder {
  color: #ffffff;
}
@media (min-width: 711px) and (max-width: 1024px) {
  header .main-navigation--wrapper .search-form input {
    width: calc( 250px + (400 - 250) * ((100vw - 711px) / (1024 - 711)) );
  }
}
@media (max-width: 1920px) {
  header .main-navigation--wrapper .search-form input {
    font-size: calc( 16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)) );
  }
}
header .main-navigation--wrapper .search-form input:focus {
  outline: none;
  box-shadow: none;
}
@media (min-width: 711px) {
  header .main-navigation--wrapper .search-form button {
    display: none;
    pointer-events: none;
  }
}
@media (max-width: 710px) {
  header .main-navigation--wrapper .search-form {
    order: 2;
    flex: 0 0 100%;
    height: 0;
    overflow: hidden;
    transition: all ease 0.45s;
  }
  header .main-navigation--wrapper .search-form input {
    width: calc( 278px + (603 - 278) * ((100vw - 375px) / (710 - 375)) );
  }
  header .main-navigation--wrapper .search-form button {
    display: none;
  }
  header .main-navigation--wrapper .search-form.search-open {
    height: auto;
    overflow: visible;
  }
}
header .explore button {
  position: relative;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 19px;
  font-family: "Surt-Bold";
  background: #ffffff;
  color: #274120;
  border: none;
  cursor: pointer;
  border-radius: 55px;
  transition: all ease 0.45s;
}
.node-foodservice header .explore button, .node-butchery header .explore button {
  color: #000;
}
@media (min-width: 711px) and (max-width: 1920px) {
  header .explore button {
    font-size: calc( 16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)) );
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 710px) {
  header .explore button {
    width: auto;
    font-size: 0;
    border-radius: 0;
    padding: 0 10px;
    background: transparent;
  }
}
header .explore button span {
  font-size: 0;
  width: 1px;
  height: 20px;
  background: #274120;
  position: absolute;
  right: 35px;
  top: 13px;
}
.node-foodservice header .explore button span, .node-butchery header .explore button span {
  background: #000;
}
@media (min-width: 711px) and (max-width: 1920px) {
  header .explore button span {
    height: calc( 12px + (16 - 12) * ((100vw - 375px) / (1920 - 375)) );
  }
}
header .explore button span + span {
  transform: rotate(90deg);
}
@media (max-width: 710px) {
  header .explore button span {
    right: 8px;
    height: 15px;
    top: 5px;
  }
}
header .explore button.menu-open span {
  visibility: hidden;
}
header .explore button.menu-open span + span {
  visibility: visible;
}
header .main-navigation--menu {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  background: #ffffff;
  padding: 0;
  border-radius: 0 0 25px 25px;
  height: 0;
  transition: all ease 0.45s;
  overflow: hidden;
  max-width: 100%;
}
header .main-navigation--menu.menu-open {
  height: auto;
  padding: 50px 0;
  overflow: visible;
}
@media (max-width: 710px) {
  header .main-navigation--menu.menu-open {
    padding: 40px 0;
  }
}
header .main-navigation--menu ul.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
header .main-navigation--menu ul.menu li a {
  color: #5c5c5c;
  font-size: 16px;
  font-family: "Surt-Regular";
  text-decoration: none;
}
@media (max-width: 1920px) {
  header .main-navigation--menu ul.menu li a {
    font-size: calc( 12px + (16 - 12) * ((100vw - 375px) / (1920 - 375)) );
  }
}
@media (max-width: 710px) {
  header .main-navigation--menu ul.menu li {
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
  header .main-navigation--menu ul.menu li a {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
header .main-navigation--menu ul.contact-us {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 710px) {
  header .main-navigation--menu ul.contact-us {
    padding-top: 20px;
    justify-content: center;
  }
}
header .main-navigation--menu ul.contact-us li {
  color: #274120;
  font-family: "Surt-Regular";
  font-size: 16px;
}
@media (max-width: 1920px) {
  header .main-navigation--menu ul.contact-us li {
    font-size: calc( 12px + (16 - 12) * ((100vw - 375px) / (1920 - 375)) );
  }
}
header .main-navigation--menu ul.contact-us li + li {
  margin-left: 5px;
}
header .main-navigation--menu ul.contact-us li a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

.slideshow {
  margin-top: -110px;
  z-index: 0;
}
.slideshow:before {
  content: "";
  z-index: 1;
  width: 100%;
  height: 460px;
  background: url(../web-images/gradiant.png);
  background-repeat: no-repeat;
  background-position: top left;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
}
@media (min-width: 711px) {
  .slideshow:before {
    background-size: 100%;
  }
}
.slideshow .mySlides {
  position: relative;
}
@media (min-width: 711px) and (max-width: 1920px) {
  .slideshow .mySlides:first-child .caption-txt h2 {
    width: calc(340px + (340 - 360) * ((100vw - 711px) / (1920 - 711)));
  }
}
.slideshow .mySlides .caption-txt {
  position: absolute;
  width: 100%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  top: 20%;
  z-index: 999;
}
@media (max-width: 710px) {
  .slideshow .mySlides .caption-txt {
    top: 12%;
  }
}
.slideshow .mySlides .caption-txt h2 {
  font-size: 40px;
  line-height: 1.2;
  font-family: "Surt-Bold";
  color: #274120;
  margin-bottom: 25px;
  width: 340px;
}
@media (max-width: 1920px) {
  .slideshow .mySlides .caption-txt h2 {
    font-size: calc(25px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
  }
}
@media (min-width: 711px) and (max-width: 1920px) {
  .slideshow .mySlides .caption-txt h2 {
    width: calc(240px + (340 - 240) * ((100vw - 711px) / (1920 - 711)));
  }
}
@media (max-width: 710px) {
  .slideshow .mySlides .caption-txt h2 {
    margin-bottom: 10px;
    width: 100%;
  }
}
.slideshow .mySlides .caption-txt .action-wrapper a {
  display: inline-block;
  padding: 13px 25px 10px;
  background: #ffffff;
  color: #274120;
  font-family: "Karbon-Semibold";
  border-radius: 25px;
  font-size: 19px;
  white-space: nowrap;
  text-decoration: none;
}
@media (max-width: 1920px) {
  .slideshow .mySlides .caption-txt .action-wrapper a {
    font-size: calc(14px + (19 - 14) * ((100vw - 375px) / (1920 - 375)));
  }
}
.slideshow .mySlides:nth-of-type(1) .caption-txt h2 {
  color: white;
  text-shadow: 0.1px 0.1px rgba(0, 0, 0, 0.3);
}
@media (min-width: 711px) {
  .slideshow .mySlides img {
    height: 813px;
    width: 100% !important;
    object-fit: cover;
  }
}
@media (max-width: 710px) {
  .slideshow .mySlides img {
    width: 100% !important;
    object-position: top;
    height: 812px;
    object-fit: cover;
  }
}
@media (min-width: 1921px) {
  .slideshow .mySlides img {
    width: 100% !important;
  }
}
.slideshow .slideshow-indicators {
  text-align: center;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}
@media (max-width: 710px) {
  .slideshow .slideshow-indicators {
    display: none;
  }
}
.slideshow .slideshow-indicators span {
  width: 7px;
  height: 7px;
  border-radius: 8px;
  background: #D8CCC7;
  border: 1px solid #919191;
  display: inline-block;
}
.slideshow .slideshow-indicators span.active {
  background: #FDCF57;
}
.slideshow .slideshow-indicators span + span {
  margin-left: 8px;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 0.1s;
  animation-name: fade;
  animation-duration: 0.1s;
}

/* .fade .caption-txt h2{
  -webkit-animation-name: fade1;
  -webkit-animation-duration: 1s;
  animation-name: fade1;
  animation-duration: 1s;
} */
@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade1 {
  from {
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes fade1 {
  from {
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}
.pp-slider h2.t-center {
  text-align: center;
}
@media (min-width: 711px) {
  .pp-slider {
    padding-left: 90px;
    padding-right: 90px;
  }
}
.pp-slider.slick-slider {
  padding-bottom: 0;
}
.pp-slider.slick-slider .slick-slide.slick-active {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
.pp-slider.slick-slider .slick-arrow {
  bottom: 0;
  width: 46px;
  height: 112px;
}
@media (max-width: 710px) {
  .pp-slider.slick-slider .slick-arrow {
    top: 0;
    width: 20px;
    height: 50px;
  }
}
.pp-slider.slick-slider .slick-arrow.slick-prev {
  background: url("../web-images/left-arrow.png") no-repeat;
  background-size: 100%;
  left: 10px;
  background-position: 0 0;
}
.pp-slider.slick-slider .slick-arrow.slick-next {
  background: url("../web-images/right-arrow.png") no-repeat;
  background-size: 100%;
  left: 93%;
  background-position: 0 0;
}

.page-home .card-main-wrapper {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 18px;
  transform: translate(-50%, 0);
  width: 100%;
}
@media (max-width: 710px) {
  .page-home .card-main-wrapper {
    bottom: 5%;
  }
}
.card-main-wrapper .card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* .not-front & {
      @media (max-width: 567px) {
        max-width: 338px;
        margin: auto;
      }
    } */
}
.not-front .card-main-wrapper .card-wrapper {
  justify-content: flex-start;
}
@media (max-width: 710px) {
  .not-front .card-main-wrapper .card-wrapper {
    justify-content: space-between;
  }
}
@media (max-width: 710px) {
  .page-home .card-main-wrapper .card-wrapper {
    max-width: 338px;
    margin: auto;
  }
}
.card-main-wrapper .card-wrapper > div {
  flex: 0 0 23%;
  max-width: 240px;
}
@media (max-width: 710px) {
  .card-main-wrapper .card-wrapper > div {
    flex: 0 0 48%;
    margin-bottom: 10px;
    max-width: 151px;
  }
}
@media (max-width: 374px) {
  .card-main-wrapper .card-wrapper > div {
    flex: 0 0 44%;
    max-width: calc(100% - 10px);
  }
}
@media (min-width: 711px) and (max-width: 1023px) {
  .not-front .card-main-wrapper .card-wrapper > div {
    flex: 0 0 22%;
  }
}
@media (min-width: 711px) {
  .not-front .card-main-wrapper .card-wrapper > div:nth-child(4n-2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .not-front .card-main-wrapper .card-wrapper > div:nth-child(4n-1) {
    margin-right: 1%;
  }
}
@media (max-width: 710px) {
  .not-front .card-main-wrapper .card-wrapper > div {
    flex: 0 0 45%;
    max-width: calc(100% - 10px);
  }
}
.card-main-wrapper .card-wrapper a {
  text-decoration: none;
}
.card-main-wrapper .card-wrapper .card {
  background: #ffffff;
  padding: 6px;
  border-radius: 35px;
  background: #ffffff;
}
.card-main-wrapper .card-wrapper .card .card--header.img-wrap {
  position: relative;
}
.card-main-wrapper .card-wrapper .card .card--header.img-wrap div.vedio-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translate(-50%, -50%);
}
.card-main-wrapper .card-wrapper .card .card--header.img-wrap div.vedio-icon img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.card-main-wrapper .card-wrapper .card img {
  width: 100%;
  border-radius: 35px;
  height: 214px;
  object-fit: cover;
}
@media (min-width: 711px) and (max-width: 1080px) {
  .card-main-wrapper .card-wrapper .card img {
    height: calc(149px + (214 - 149) * ((100vw - 711px) / (1080 - 711)));
  }
}
@media (max-width: 710px) {
  .card-main-wrapper .card-wrapper .card img {
    height: calc(134px + (269 - 134) * ((100vw - 375px) / (710 - 375)));
  }
}
@media (max-width: 710px) {
  .card-main-wrapper .card-wrapper .card {
    border-radius: 25px;
  }
  .card-main-wrapper .card-wrapper .card img {
    border-radius: 25px;
  }
}
.card-main-wrapper .card-wrapper .card .card--footer {
  padding: 20px 0 25px;
  font-size: 19px;
  color: #274120;
  text-align: center;
  line-height: 1.2;
  font-family: "Surt-Regular";
}
.node-foodservice .card-main-wrapper .card-wrapper .card .card--footer {
  color: #2f2f2f;
}
.node-butchery .card-main-wrapper .card-wrapper .card .card--footer {
  color: #606060;
}
@media (max-width: 1920px) {
  .card-main-wrapper .card-wrapper .card .card--footer {
    font-size: calc(14px + (19 - 14) * ((100vw - 375px) / (1920 - 375)));
    padding: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375))) 0 calc(17px + (25 - 17) * ((100vw - 375px) / (1920 - 375)));
  }
}
.card-main-wrapper .card-wrapper .card .card--footer b {
  font-family: "Surt-Bold";
}
.not-front .card-main-wrapper .card-wrapper .card .card--footer b {
  line-height: 1.2;
}
.card-main-wrapper .card-wrapper.li-wrapper > div:nth-of-type(1) {
  margin-right: 2.5%;
}

.bg-card-wrapper {
  background: #ffffff;
  border-radius: 25px;
  background-clip: padding-box;
  padding: 3px;
  margin-bottom: 40px;
}
@media (max-width: 1920px) {
  .bg-card-wrapper {
    margin-bottom: calc(35px + (40 - 35) * ((100vw - 375px) / (1920 - 375)));
  }
}
.bg-card-wrapper .bg-card-header.img-wrap {
  position: relative;
}
.bg-card-wrapper .bg-card-header.img-wrap .vedio-icon {
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bg-card-wrapper .bg-card-header.img-wrap .vedio-icon img {
  width: 91px;
}
@media (max-width: 1920px) {
  .bg-card-wrapper .bg-card-header.img-wrap .vedio-icon img {
    width: calc(40px + (91 - 40) * ((100vw - 375px) / (1920 - 375)));
  }
}
.bg-card-wrapper .bg-card-header picture img {
  border-radius: 25px;
  width: 100% !important;
  height: 330px;
  object-fit: cover;
}
@media (max-width: 1920px) {
  .bg-card-wrapper .bg-card-header picture img {
    height: calc(140px + (330 - 140) * ((100vw - 375px) / (1920 - 375)));
  }
}
@media (max-width: 710px) {
  .bg-card-wrapper .bg-card-header picture img {
    border-radius: 35px;
  }
}
.bg-card-wrapper .bg-card-content {
  padding: 35px 45px;
}
@media (max-width: 1920px) {
  .bg-card-wrapper .bg-card-content {
    padding: calc(25px + (35 - 25) * ((100vw - 375px) / (1920 - 375))) calc(17px + (45 - 17) * ((100vw - 375px) / (1920 - 375)));
  }
}
.bg-card-wrapper .bg-card-content .bg-card-body h2 {
  color: #214f2b;
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.2;
  font-family: "Surt-Bold";
}
.node-foodservice .bg-card-wrapper .bg-card-content .bg-card-body h2 {
  color: #2f2f2f;
}
.node-butchery .bg-card-wrapper .bg-card-content .bg-card-body h2 {
  color: #541818;
}
.page-cuts-by-protien.node-beef .bg-card-wrapper .bg-card-content .bg-card-body h2 {
  color: #7f1718;
}
.page-cuts-by-protien.node-goat .bg-card-wrapper .bg-card-content .bg-card-body h2 {
  color: #ffc222;
}
@media (max-width: 1080px) {
  .bg-card-wrapper .bg-card-content .bg-card-body h2 {
    font-size: calc(15px + (24 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.bg-card-wrapper .bg-card-content .bg-card-body ul {
  list-style-type: initial;
  padding-left: 15px;
}
.bg-card-wrapper .bg-card-content .bg-card-body ul li {
  padding-left: 16px;
  line-height: 1.5;
}
.bg-card-wrapper .bg-card-content .bg-card-body ul li + li {
  margin-top: 10px;
}
.bg-card-wrapper .bg-card-content .bg-card-body p {
  font-size: 18px;
  font-family: "Surt-Regular";
  line-height: 1.5;
  margin-bottom: 20px;
}
@media (max-width: 1080px) {
  .bg-card-wrapper .bg-card-content .bg-card-body p {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 1080px) {
  .bg-card-wrapper .bg-card-content .bg-card-body p br {
    display: none;
  }
}
.node-foodservice .bg-card-wrapper .bg-card-content .bg-card-body p {
  color: #2f2f2f;
}
.node-foodservice .bg-card-wrapper .bg-card-content .bg-card-body p a {
  color: #2f2f2f;
  text-decoration: none;
}
.node-foodservice .bg-card-wrapper .bg-card-content .bg-card-body {
  color: #2f2f2f;
}
.bg-card-wrapper .bg-card-content .bg-card-footer a.action-btn {
  display: inline-block;
  padding: 9px 25px;
  background: #fec839;
  color: #274120;
  font-family: "Surt-Bold";
  border-radius: 25px;
  font-size: 18px;
  white-space: nowrap;
  margin-bottom: 20px;
  text-decoration: none;
}
.node-foodservice .bg-card-wrapper .bg-card-content .bg-card-footer a.action-btn {
  background: #111111;
  color: #ffffff;
}
@media (max-width: 1920px) {
  .bg-card-wrapper .bg-card-content .bg-card-footer a.action-btn {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
  }
}
.bg-card-wrapper.meatcuts-card {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.bg-card-wrapper.meatcuts-card .bg-card-header {
  flex: 0 0 29%;
}
.bg-card-wrapper.meatcuts-card .bg-card-header picture {
  display: flex;
  height: 100%;
}
.bg-card-wrapper.meatcuts-card .bg-card-header img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 710px) {
  .bg-card-wrapper.meatcuts-card .bg-card-header {
    flex: 0 0 100%;
  }
  .bg-card-wrapper.meatcuts-card .bg-card-header img {
    height: calc(140px + (181 - 140) * ((100vw - 375px) / (710 - 375)));
    object-fit: cover;
    margin-bottom: 15px;
  }
}
.bg-card-wrapper.meatcuts-card .bg-card-content {
  flex: 1;
  padding: 15px;
}
.bg-card-wrapper.meatcuts-card .bg-card-footer a.action-btn {
  margin-bottom: 0;
}

main {
  position: relative;
}

.meatcuts-card-container {
  padding-top: 45px;
}
@media (max-width: 710px) {
  .meatcuts-card-container {
    padding-top: 25px;
  }
}

section.cart-wrapper {
  position: fixed;
  right: 0;
}
@media (min-width: 711px) {
  section.cart-wrapper {
    top: 40%;
  }
  section.cart-wrapper.stt-intersect {
    top: 20%;
  }
}
@media (max-width: 710px) {
  section.cart-wrapper {
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }
}
section.cart-wrapper a {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 25px 35px 25px 45px;
  font-size: 16px;
  font-family: "Surt-Regular";
  text-decoration: none;
  color: #ffffff;
  background: #274120;
  border-radius: 25px 0 0 25px;
  position: relative;
}
.node-foodservice section.cart-wrapper a {
  background: #111111;
}
.node-butchery section.cart-wrapper a {
  background: #541818;
}
@media (min-width: 711px) {
  section.cart-wrapper a {
    width: 79px;
    padding: 20px 35px 16px 25px;
  }
}
@media (max-width: 710px) {
  section.cart-wrapper a {
    padding: 7px 20%;
    justify-content: center;
    align-items: center;
    border-radius: 30px 30px 0 0;
  }
}
section.cart-wrapper a:before {
  content: "";
  width: 1px;
  height: 60%;
  border-left: 2px dotted #51744A;
  display: inline-block;
  position: absolute;
  left: 22px;
}
.node-foodservice section.cart-wrapper a:before {
  border-color: #888 !important;
}
.node-butchery section.cart-wrapper a:before {
  border-color: #aa8c8c !important;
}
@media (max-width: 710px) {
  section.cart-wrapper a:before {
    display: none;
  }
}
section.cart-wrapper a span {
  display: inline-block;
  width: 60px;
  line-height: 1.2;
}
@media (max-width: 710px) {
  section.cart-wrapper a span {
    width: auto;
  }
}
@media (min-width: 711px) {
  section.cart-wrapper a span {
    padding-left: 20px;
  }
}
section.cart-wrapper a svg {
  width: 60px;
  height: 28px;
  fill: #f4c34d;
  margin-bottom: 10px;
}
.node-foodservice section.cart-wrapper a svg {
  fill: #eff9f1;
}
section.cart-wrapper a img {
  flex: 0 0 100%;
  height: 28px;
  object-fit: contain;
  object-position: left;
  margin-bottom: 10px;
}
@media (min-width: 711px) {
  section.cart-wrapper a img {
    margin-left: 20px;
  }
}
@media (max-width: 710px) {
  section.cart-wrapper a img {
    flex: 0 0 auto;
    margin-bottom: 0;
    padding-left: 20px;
    border-left: 2px dotted #51744A;
    margin-right: 10px;
  }
}

.page-home footer {
  margin-top: -10px;
}
footer .f-top {
  background: #214f2b;
  color: #ffffff;
  padding: 30px 0 40px;
}
.node-foodservice footer .f-top {
  background: #111111;
}
.node-butchery footer .f-top {
  background: #541818;
}
@media (min-height: 1199px) {
  footer .f-top {
    padding: 4% 0;
  }
}
footer .f-top * {
  color: inherit;
  text-decoration: none;
}
footer .f-top .f-social--wrapper {
  display: none;
}
@media (max-width: 710px) {
  footer .f-top .f-social--wrapper {
    display: block;
  }
  footer .f-top .f-social--wrapper .f-social--links {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer .f-top .f-social--wrapper .f-social--links li + li {
    margin-left: 10px;
  }
  footer .f-top .f-social--wrapper .f-social--links li a {
    font-size: 0;
    width: 30px;
    height: 30px;
    display: inline-block;
    background-image: url("../web-images/socialMobile.png");
    background-repeat: no-repeat;
  }
  .node-foodservice footer .f-top .f-social--wrapper .f-social--links li a, .node-butchery footer .f-top .f-social--wrapper .f-social--links li a {
    background-image: url("../web-images/socialMobile-white.png");
  }
  footer .f-top .f-social--wrapper .f-social--links li a.tw {
    background-position: -44px 0;
  }
  footer .f-top .f-social--wrapper .f-social--links li a.in {
    background-position: -88px 0;
  }
}
footer .f-top .f-top--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 710px) {
  footer .f-top .f-top--wrapper > div {
    flex: 0 0 100%;
    text-align: center;
  }
  footer .f-top .f-top--wrapper > div + div {
    padding-top: 10px;
  }
}
footer .f-top .f-top--wrapper p {
  padding-bottom: 10px;
  font-size: 19px;
  line-height: 1;
  font-family: "Surt-Bold";
  border-bottom: 2px dotted #51744A;
  pointer-events: none;
}
@media (max-width: 1920px) {
  footer .f-top .f-top--wrapper p {
    font-size: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
  }
}
.node-foodservice footer .f-top .f-top--wrapper p {
  border-color: #888888 !important;
}
.node-butchery footer .f-top .f-top--wrapper p {
  border-color: #aa8c8c !important;
}
footer .f-top .f-top--wrapper p a {
  pointer-events: initial !important;
}
@media (max-width: 710px) {
  footer .f-top .f-top--wrapper p {
    pointer-events: initial;
    border-bottom: 1px solid #51744A;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}
@media (min-width: 711px) {
  footer .f-top .f-top--wrapper p {
    display: inline-block;
  }
}
footer .f-top .f-top--wrapper ul li {
  margin-top: 10px;
  width: 100%;
}
footer .f-top .f-top--wrapper ul li a {
  font-size: 16px;
  font-family: "Surt-Regular";
  line-height: 1;
}
@media (max-width: 1920px) {
  footer .f-top .f-top--wrapper ul li a {
    font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  }
}
@media (max-width: 710px) {
  footer .f-top .f-top--wrapper .f-top--item p {
    cursor: pointer;
    position: relative;
    z-index: 0;
  }
  footer .f-top .f-top--wrapper .f-top--item p:before, footer .f-top .f-top--wrapper .f-top--item p:after {
    content: "";
    width: 15px;
    height: 1px;
    background: #c6c6c6;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: -1;
    display: inline-block;
  }
  footer .f-top .f-top--wrapper .f-top--item p:after {
    transform: rotate(90deg);
  }
  footer .f-top .f-top--wrapper .f-top--item ul {
    transition: all ease 0.45s;
  }
  footer .f-top .f-top--wrapper .f-top--item.close ul {
    height: 0;
    overflow: hidden;
  }
  footer .f-top .f-top--wrapper .f-top--item.open p {
    border-bottom: none;
  }
  footer .f-top .f-top--wrapper .f-top--item.open p:after {
    display: none;
  }
  footer .f-top .f-top--wrapper .f-top--item.open ul {
    height: auto;
    overflow: visible;
    border-bottom: 1px solid #51744A;
    padding-bottom: 10px;
  }
  .node-foodservice footer .f-top .f-top--wrapper .f-top--item.open ul {
    border-color: #888888 !important;
  }
  .node-butchery footer .f-top .f-top--wrapper .f-top--item.open ul {
    border-color: #aa8c8c !important;
  }
}
footer .f-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
@media (max-width: 600px) {
  footer .f-bottom {
    padding: 15px 0 52px;
  }
}
footer .f-bottom > div ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 600px) {
  footer .f-bottom .f-bottom-menu {
    width: 100%;
  }
}
footer .f-bottom .f-bottom-menu ul {
  width: 474px;
  justify-content: space-between;
}
@media (max-width: 600px) {
  footer .f-bottom .f-bottom-menu ul {
    width: 100%;
    flex: 0 0 100%;
    margin-top: 10px;
  }
}
@media (min-width: 601px) and (max-width: 769px) {
  footer .f-bottom .f-bottom-menu ul {
    width: 350px;
  }
}
footer .f-bottom .f-bottom-menu ul a {
  color: #214f2b;
  text-decoration: none;
  font-family: "Surt-Regular";
  font-size: 14px;
}
.node-foodservice footer .f-bottom .f-bottom-menu ul a, .node-butchery footer .f-bottom .f-bottom-menu ul a {
  color: #606060;
}
@media (max-width: 1920) {
  footer .f-bottom .f-bottom-menu ul a {
    font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
  }
}
@media (max-width: 600px) {
  footer .f-bottom .app-store {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
footer .f-bottom .app-store ul li {
  font-size: 16px;
  font-family: "Surt-Regular";
  color: #214f2b;
}
@media (max-width: 710px) {
  footer .f-bottom .app-store ul li {
    font-family: "Surt-Bold";
  }
}
footer .f-bottom .app-store ul li:nth-last-of-type(1) {
  position: relative;
  top: 1px;
}
.node-foodservice footer .f-bottom .app-store ul li, .node-butchery footer .f-bottom .app-store ul li {
  color: #606060;
}
footer .f-bottom .app-store ul li a {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  background-image: url("../web-images/app-store.png");
  background-repeat: no-repeat;
}
.node-foodservice footer .f-bottom .app-store ul li a {
  background-image: url("../web-images/app-store-black.png");
}
.node-butchery footer .f-bottom .app-store ul li a {
  background-image: url("../web-images/app-store-brown.png");
}
footer .f-bottom .app-store ul li a.play-store {
  background-position: -30px 0;
}
footer .f-bottom .app-store ul li + li {
  margin-left: 10px;
}

.page-banner {
  margin-top: -113px;
  z-index: 0;
}
@media (max-width: 710px) {
  body.open-search .page-banner {
    margin-top: -125px;
  }
}
.page-banner .banner-container {
  position: relative;
}
.not-front .page-banner .banner-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../web-images/gradiant.png");
  background-repeat: no-repeat;
  background-position: top left;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
}
@media (min-width: 1921px) {
  .not-front .page-banner .banner-container::before {
    background-size: 100%;
  }
}
.page-banner .banner-container img {
  height: 475px;
  width: 100% !important;
  object-fit: cover;
  object-position: top right;
}
@media (max-width: 1920px) {
  .page-banner .banner-container img {
    height: calc(315px + (470 - 315) * ((100vw - 375px) / (1920 - 375)));
  }
}
.page-resource-centre .page-banner .banner-container img, .node-foodservice .page-banner .banner-container img {
  object-position: center center;
}
.page-aussome-recipes-inner .page-banner .banner-container img {
  object-position: left;
}
@media (max-width: 710px) {
  .page-aussome-recipes-inner .page-banner .banner-container img {
    object-position: center center;
  }
}
.page-banner .banner-container .caption-txt {
  position: absolute;
  width: 100%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  top: 41%;
}
@media (max-width: 710px) {
  .page-banner .banner-container .caption-txt {
    top: 36%;
  }
}
.page-banner .banner-container .caption-txt h1 {
  font-size: 40px;
  line-height: 1.2;
  font-family: "Surt-Bold";
  color: #274120;
  margin-bottom: 25px;
  letter-spacing: 1;
  width: 600px;
}
@media (max-width: 710px) {
  .page-banner .banner-container .caption-txt h1 {
    line-height: 1.5em;
  }
}
.node-foodservice .page-banner .banner-container .caption-txt h1 {
  color: #2a2a2a;
}
.node-butchery .page-banner .banner-container .caption-txt h1 {
  color: #541818;
}
.page-banner .banner-container .caption-txt h1.w-335, .page-banner .banner-container .caption-txt h1.w-375, .page-aussome-recipes .page-banner .banner-container .caption-txt h1, .page-cooking-methods .page-banner .banner-container .caption-txt h1, .page-cuts-by-protien .page-banner .banner-container .caption-txt h1 {
  width: 380px;
}
@media (max-width: 710px) {
  .page-banner .banner-container .caption-txt h1.w-335, .page-banner .banner-container .caption-txt h1.w-375, .page-aussome-recipes .page-banner .banner-container .caption-txt h1, .page-cooking-methods .page-banner .banner-container .caption-txt h1, .page-cuts-by-protien .page-banner .banner-container .caption-txt h1 {
    width: 100%;
  }
}
@media (max-width: 710px) {
  .page-menu-inspiration .page-banner .banner-container .caption-txt h1.w-335, .page-menu-inspiration .page-banner .banner-container .caption-txt h1.w-375, .page-menu-inspiration .page-aussome-recipes .page-banner .banner-container .caption-txt h1, .page-menu-inspiration .page-cooking-methods .page-banner .banner-container .caption-txt h1, .page-menu-inspiration .page-cuts-by-protien .page-banner .banner-container .caption-txt h1 {
    width: 200px;
  }
}
.page-meatcuts-inner .page-banner .banner-container .caption-txt h1 {
  width: 320px;
}
@media (min-width: 711px) {
  .page-meatcuts-inner .page-banner .banner-container .caption-txt h1 {
    width: calc(220px + (320 - 220) * ((100vw - 711px) / (1920 - 711)));
  }
}
@media (max-width: 710px) {
  .page-meatcuts-inner .page-banner .banner-container .caption-txt h1 {
    width: 100%;
  }
}
.page-banner .banner-container .caption-txt h1.w-350 {
  width: 350px;
}
@media (min-width: 711px) {
  .page-banner .banner-container .caption-txt h1.w-350 {
    width: calc(250px + (350 - 250) * ((100vw - 711px) / (1920 - 711)));
  }
}
@media (max-width: 710px) {
  .page-banner .banner-container .caption-txt h1.w-350 {
    width: 100%;
  }
}
@media (min-width: 711px) and (max-width: 1920px) {
  .page-banner .banner-container .caption-txt h1 {
    width: calc(480px + (480 - 280) * ((100vw - 711px) / (1920 - 711)));
  }
  .page-aussome-recipes .page-banner .banner-container .caption-txt h1, .page-cooking-methods .page-banner .banner-container .caption-txt h1, .page-banner .banner-container .caption-txt h1.w-375 {
    width: calc(380px + (380 - 280) * ((100vw - 711px) / (1920 - 711)));
  }
  .page-banner .banner-container .caption-txt h1.w-335 {
    width: calc(380px + (380 - 280) * ((100vw - 711px) / (1920 - 711)));
  }
}
@media (max-width: 1920px) {
  .page-banner .banner-container .caption-txt h1 {
    font-size: calc(21px + (40 - 21) * ((100vw - 375px) / (1920 - 375)));
  }
}
@media (max-width: 710px) {
  .page-banner .banner-container .caption-txt h1 {
    position: relative;
    z-index: 0;
    margin-bottom: 15px;
    width: 300px !important;
  }
}
@media (min-width: 711px) and (max-width: 1024px) {
  .page-banner .banner-container .caption-txt h1 {
    width: 600px !important;
  }
}
.not-front .page-banner .banner-container .caption-txt h1 span {
  background-color: #fff;
  padding: 2px 25px 2px 25px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 1.5em;
  border-radius: 20px;
}
@media (max-width: 710px) {
  .not-front .page-banner .banner-container .caption-txt h1 span {
    line-height: 1.7;
    position: relative;
  }
}
.page-resources .page-banner .banner-container .bg-card-wrapper {
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
}
.page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header img {
  height: 475px;
  border-radius: 0;
}
@media (max-width: 1920px) {
  .page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header img {
    height: calc(315px + (470 - 315) * ((100vw - 375px) / (1920 - 375)));
  }
}
.page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header.img-wrap div.vedio-icon {
  z-index: 100;
}
.page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header.img-wrap div.vedio-icon a {
  display: inline-block;
}
.page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header.img-wrap div.vedio-icon a img {
  width: 100px;
  height: 100px;
}
@media (max-width: 1920px) {
  .page-resources .page-banner .banner-container .bg-card-wrapper .bg-card-header.img-wrap div.vedio-icon a img {
    width: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
    height: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
  }
}

header.menu-open + main .slideshow .mySlides .caption-txt {
  z-index: 0;
}

@media (max-width: 710px) {
  div[class^="breadcrum mb-"],
div[class^="breadcrum mt-"] {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
.breadcrum {
  padding-top: 10px;
  padding-bottom: 25px;
}
@media (max-width: 710px) {
  .breadcrum {
    padding-bottom: 0;
  }
}
.breadcrum ol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #000000;
}
@media (max-width: 710px) {
  .breadcrum ol {
    display: none;
  }
}
.breadcrum ol * {
  text-decoration: none;
  color: inherit;
}
.breadcrum ol li {
  font-size: 15px;
}
.breadcrum ol li:last-of-type a::after {
  display: none;
}
.breadcrum ol li + li {
  margin-left: 5px;
}
@media (max-width: 1920px) {
  .breadcrum ol li {
    font-size: calc(9px + (15 - 9) * ((100vw - 375px) / (1920 - 375)));
  }
}
.breadcrum ol li a {
  position: relative;
  font-family: "Surt-Regular";
  font-size: inherit;
}
.breadcrum ol li a:after {
  content: ">";
  display: inline-block;
  vertical-align: bottom;
  pointer-events: none;
  margin-left: 5px;
}
.breadcrum ol li span {
  font-family: "Surt-Bold";
  font-size: inherit;
}

.scroll-to-top {
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: right;
}
.scroll-to-top svg {
  cursor: pointer;
  opacity: 0;
  transform: translateY(200px);
  transition: all ease 0.5s;
}
.scroll-to-top.showBtn svg {
  opacity: 1;
  transform: translateY(0);
}

.link-tabs ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  background: #eeeeee;
  border-radius: 20px;
  max-width: max-content;
}
@media (max-width: 439px) {
  .page-cooking-methods .link-tabs ul {
    background: none;
  }
}
.link-tabs ul li a {
  text-decoration: none;
  background: #eeeeee;
  color: #000000;
  font-family: "Surt-Bold";
  font-size: 20px;
  line-height: 1;
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
@media (max-width: 1920px) {
  .link-tabs ul li a {
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  }
}
.link-tabs ul li:nth-of-type(1) a {
  border-radius: 20px 0 0 20px;
}
.link-tabs ul li:nth-of-type(1) a:before {
  display: none;
}
.link-tabs ul li:nth-last-of-type(1) a {
  border-radius: 0 20px 20px 0;
}
.link-tabs ul li:nth-last-of-type(1) a:after {
  display: none;
}
.link-tabs ul li a.active-link, .link-tabs ul li a.active {
  background: #556b2a;
  border-radius: 20px;
  color: #ffffff;
}
.page-know-your-cuts .link-tabs ul li a.active-link, .page-know-your-cuts .link-tabs ul li a.active {
  background-color: #541818;
}
.page-cuts-by-protien.node-beef .link-tabs ul li a.active-link, .page-cuts-by-protien.node-beef .link-tabs ul li a.active {
  background-color: #7f1718;
}
.page-cuts-by-protien.node-goat .link-tabs ul li a.active-link, .page-cuts-by-protien.node-goat .link-tabs ul li a.active {
  background-color: #ffc222;
}
@media (max-width: 500px) {
  .page-cooking-methods .link-tabs ul li a {
    display: inline-block;
    border-radius: 20px;
  }
  .page-cooking-methods .link-tabs ul li a:before, .page-cooking-methods .link-tabs ul li a:after {
    display: none;
  }
}

.b-tab {
  padding: 0;
  border: none;
  display: block;
  height: 0;
  overflow: hidden;
}
.b-tab.active {
  height: auto;
  padding: 20px;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
    height: 0;
  }
  50%, 100% {
    opacity: 1;
    height: auto;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0;
    height: 0;
  }
  50%, 100% {
    opacity: 1;
    height: auto;
  }
}
.tags-wrapper .tags-title {
  color: #5c5c5c;
  font-size: 14px;
  font-family: "Surt-Regular";
  margin-bottom: 10px;
  display: none;
}
.tags-wrapper .tags-list ul li {
  margin-right: 10px;
}
.tags-wrapper .tags-list ul li:nth-last-of-type(1) {
  margin-right: 0;
}
.tags-wrapper .tags-list ul li a {
  text-decoration: none;
  font-family: "Surt-Bold";
  font-size: 18px;
  color: #5c5c5c;
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 18px;
  display: inline-block;
  margin-bottom: 10px;
}
@media (max-width: 1080px) {
  .tags-wrapper .tags-list ul li a {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.tags-wrapper .tags-list ul li a.active-link {
  background-color: #274120;
  color: #fff;
}
.page-cuts-by-protien.node-beef .tags-wrapper .tags-list ul li a.active-link {
  background-color: #7f1718;
}

.page-about main,
.not-front main {
  background: #f7f7f7;
}
.page-about h2.heading,
.not-front h2.heading {
  color: #5c7b35;
  font-size: 26px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-family: "Surt-Regular";
}
@media (max-width: 1080px) {
  .page-about h2.heading,
.not-front h2.heading {
    font-size: calc(16px + (26 - 16) * ((100vw - 375px) / (1080 - 375)));
  }
  .page-about h2.heading br,
.not-front h2.heading br {
    display: none;
  }
}
@media (max-width: 1080px) {
  .page-about h2.heading,
.not-front h2.heading {
    padding-right: 15px;
  }
}

.not-front.node-foodservice main {
  background: #fbf9ec;
}
.not-front.node-foodservice h2.heading {
  color: #2f2f2f;
}

.not-front.node-butchery main {
  background: #f2f2f2;
}
.not-front.node-butchery h2.heading {
  color: #606060;
}

.page-cuts-by-protien.node-beef.not-front h2.heading {
  color: #7f1718;
}

.page-cuts-by-protien.node-goat.not-front h2.heading {
  color: #ffc222;
}

.page-aussome-recipes-detail header {
  padding-bottom: 30px;
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail header {
    padding-bottom: 0;
  }
}
.page-aussome-recipes-detail .breadcrum {
  padding: 30px 0;
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .breadcrum {
    padding: 5px 0;
  }
}
.page-aussome-recipes-detail .ingredients-container {
  max-width: 750px;
  overflow-x: auto;
  justify-content: space-between;
}
.page-aussome-recipes-detail .ingredients-container table {
  width: 750px;
  border: none !important;
}
.page-aussome-recipes-detail .ingredients-container table th, .page-aussome-recipes-detail .ingredients-container table td {
  padding: 5px;
  border: none !important;
  text-align: left;
}
.page-aussome-recipes-detail .ingredients-container table th {
  background: #f7f7f7;
}
.page-aussome-recipes-detail .ingredients-container > p {
  flex: 0 0 47%;
  margin-bottom: 10px;
  font-size: 18px;
  padding-right: 1%;
  font-family: "Surt-Regular";
}
.page-aussome-recipes-detail .ingredients-container > p b {
  margin-top: 10px;
}
@media (min-width: 711px) {
  .page-aussome-recipes-detail .ingredients-container > p b {
    display: inline-block;
    width: 600px;
  }
}
@media (max-width: 1080px) {
  .page-aussome-recipes-detail .ingredients-container > p {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .ingredients-container > p {
    flex: 0 0 100%;
    margin-bottom: 5px;
    padding-right: 0;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row {
  flex-wrap: wrap;
  background: #ffffff;
  padding: 5px;
  border-radius: 30px;
  background-clip: padding-box;
  margin-bottom: 25px;
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .grid-container .grid-row {
    justify-content: center;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-of-type(1) {
  flex: 0 0 67%;
}
@media (min-width: 1024px) and (max-width: 1050px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-of-type(1) {
    flex: 0 0 60%;
  }
}
@media (min-width: 711px) and (max-width: 1023px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-of-type(1) {
    flex: 0 0 55%;
  }
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-of-type(1) {
    flex: 0 0 100%;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-last-of-type(1) {
  flex: 0 0 32%;
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col:nth-last-of-type(1) {
    flex: 0 0 100%;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  background-clip: padding-box;
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap picture img {
  width: 100% !important;
  height: 409px;
  object-fit: cover;
  transform: scale(1.1, 1.1);
}
@media (min-width: 1024px) and (max-width: 1050px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap picture img {
    height: 300px;
  }
}
@media (min-width: 711px) and (max-width: 1023px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap picture img {
    height: calc(255px + (300 - 255) * ((100vw - 711px) / (1023 - 711)));
  }
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap picture img {
    height: 255px;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap .vedio-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  cursor: pointer;
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .img-wrap .vedio-icon img {
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .content-wrap {
  padding: 15px;
  height: 100%;
}
@media (max-width: 710px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col .content-wrap {
    justify-content: center;
  }
}
.page-aussome-recipes-detail .grid-container .grid-row .grid-col .content-wrap h1 {
  font-size: 26px;
  color: #7f1718;
  text-align: center;
  font-family: "Surt-Bold";
}
@media (max-width: 1080px) {
  .page-aussome-recipes-detail .grid-container .grid-row .grid-col .content-wrap h1 {
    font-size: calc(16px + (26 - 16) * ((100vw - 375px) / (1080 - 375)));
  }
}

.not-front.page-terms .content-bg-fff a, .not-front.page-privacy .content-bg-fff a, .not-front.page-cookiespolicy .content-bg-fff a {
  color: #214f2b;
}
.not-front.page-more-tips-detail h2.heading, .not-front.page-cooking-methods-detail h2.heading, .not-front.page-nutrition-detail h2.heading, .not-front.page-terms h2.heading, .not-front.page-privacy h2.heading, .not-front.page-cookiespolicy h2.heading, .not-front.page-animal-welfare h2.heading, .not-front.page-house-rules h2.heading {
  line-height: 1.5;
  margin-bottom: 50px;
}
.not-front.page-more-tips-detail .hash-link-wrapper, .not-front.page-cooking-methods-detail .hash-link-wrapper, .not-front.page-nutrition-detail .hash-link-wrapper, .not-front.page-terms .hash-link-wrapper, .not-front.page-privacy .hash-link-wrapper, .not-front.page-cookiespolicy .hash-link-wrapper, .not-front.page-animal-welfare .hash-link-wrapper, .not-front.page-house-rules .hash-link-wrapper {
  display: inline-block;
  margin-bottom: 30px;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul, .not-front.page-cooking-methods-detail .hash-link-wrapper ul, .not-front.page-nutrition-detail .hash-link-wrapper ul, .not-front.page-terms .hash-link-wrapper ul, .not-front.page-privacy .hash-link-wrapper ul, .not-front.page-cookiespolicy .hash-link-wrapper ul, .not-front.page-animal-welfare .hash-link-wrapper ul, .not-front.page-house-rules .hash-link-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li, .not-front.page-nutrition-detail .hash-link-wrapper ul li, .not-front.page-terms .hash-link-wrapper ul li, .not-front.page-privacy .hash-link-wrapper ul li, .not-front.page-cookiespolicy .hash-link-wrapper ul li, .not-front.page-animal-welfare .hash-link-wrapper ul li, .not-front.page-house-rules .hash-link-wrapper ul li {
  margin-bottom: 10px;
  margin-right: 10px;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li a, .not-front.page-nutrition-detail .hash-link-wrapper ul li a, .not-front.page-terms .hash-link-wrapper ul li a, .not-front.page-privacy .hash-link-wrapper ul li a, .not-front.page-cookiespolicy .hash-link-wrapper ul li a, .not-front.page-animal-welfare .hash-link-wrapper ul li a, .not-front.page-house-rules .hash-link-wrapper ul li a {
  display: inline-block;
  font-family: "Surt-Bold";
  white-space: nowrap;
  text-decoration: none;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 25px;
}
@media (max-width: 710px) {
  .not-front.page-more-tips-detail .hash-link-wrapper ul li a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li a, .not-front.page-nutrition-detail .hash-link-wrapper ul li a, .not-front.page-terms .hash-link-wrapper ul li a, .not-front.page-privacy .hash-link-wrapper ul li a, .not-front.page-cookiespolicy .hash-link-wrapper ul li a, .not-front.page-animal-welfare .hash-link-wrapper ul li a, .not-front.page-house-rules .hash-link-wrapper ul li a {
    white-space: inherit;
  }
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li a:hover, .not-front.page-more-tips-detail .hash-link-wrapper ul li a:focus, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li a:hover, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li a:focus, .not-front.page-nutrition-detail .hash-link-wrapper ul li a:hover, .not-front.page-nutrition-detail .hash-link-wrapper ul li a:focus, .not-front.page-terms .hash-link-wrapper ul li a:hover, .not-front.page-terms .hash-link-wrapper ul li a:focus, .not-front.page-privacy .hash-link-wrapper ul li a:hover, .not-front.page-privacy .hash-link-wrapper ul li a:focus, .not-front.page-cookiespolicy .hash-link-wrapper ul li a:hover, .not-front.page-cookiespolicy .hash-link-wrapper ul li a:focus, .not-front.page-animal-welfare .hash-link-wrapper ul li a:hover, .not-front.page-animal-welfare .hash-link-wrapper ul li a:focus, .not-front.page-house-rules .hash-link-wrapper ul li a:hover, .not-front.page-house-rules .hash-link-wrapper ul li a:focus {
  outline: normal;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-nutrition-detail .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-terms .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-privacy .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-cookiespolicy .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-animal-welfare .hash-link-wrapper ul li:nth-child(4n-3) a, .not-front.page-house-rules .hash-link-wrapper ul li:nth-child(4n-3) a {
  background: #214f2b;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-nutrition-detail .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-terms .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-privacy .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-cookiespolicy .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-animal-welfare .hash-link-wrapper ul li:nth-child(4n-2) a, .not-front.page-house-rules .hash-link-wrapper ul li:nth-child(4n-2) a {
  background: #fec223;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-nutrition-detail .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-terms .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-privacy .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-cookiespolicy .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-animal-welfare .hash-link-wrapper ul li:nth-child(4n-1) a, .not-front.page-house-rules .hash-link-wrapper ul li:nth-child(4n-1) a {
  background: #5c7b35;
}
.not-front.page-more-tips-detail .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-cooking-methods-detail .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-nutrition-detail .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-terms .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-privacy .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-cookiespolicy .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-animal-welfare .hash-link-wrapper ul li:nth-child(4n) a, .not-front.page-house-rules .hash-link-wrapper ul li:nth-child(4n) a {
  background: #505050;
}
.not-front.page-more-tips-detail .img-text-container, .not-front.page-cooking-methods-detail .img-text-container, .not-front.page-nutrition-detail .img-text-container, .not-front.page-terms .img-text-container, .not-front.page-privacy .img-text-container, .not-front.page-cookiespolicy .img-text-container, .not-front.page-animal-welfare .img-text-container, .not-front.page-house-rules .img-text-container {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 710px) {
  .not-front.page-more-tips-detail .img-text-container, .not-front.page-cooking-methods-detail .img-text-container, .not-front.page-nutrition-detail .img-text-container, .not-front.page-terms .img-text-container, .not-front.page-privacy .img-text-container, .not-front.page-cookiespolicy .img-text-container, .not-front.page-animal-welfare .img-text-container, .not-front.page-house-rules .img-text-container {
    justify-content: center;
  }
}
.not-front.page-more-tips-detail .img-text-container .lt-img-wrapper, .not-front.page-cooking-methods-detail .img-text-container .lt-img-wrapper, .not-front.page-nutrition-detail .img-text-container .lt-img-wrapper, .not-front.page-terms .img-text-container .lt-img-wrapper, .not-front.page-privacy .img-text-container .lt-img-wrapper, .not-front.page-cookiespolicy .img-text-container .lt-img-wrapper, .not-front.page-animal-welfare .img-text-container .lt-img-wrapper, .not-front.page-house-rules .img-text-container .lt-img-wrapper {
  flex: 0 0 180px;
}
@media (max-width: 710px) {
  .not-front.page-more-tips-detail .img-text-container .lt-img-wrapper, .not-front.page-cooking-methods-detail .img-text-container .lt-img-wrapper, .not-front.page-nutrition-detail .img-text-container .lt-img-wrapper, .not-front.page-terms .img-text-container .lt-img-wrapper, .not-front.page-privacy .img-text-container .lt-img-wrapper, .not-front.page-cookiespolicy .img-text-container .lt-img-wrapper, .not-front.page-animal-welfare .img-text-container .lt-img-wrapper, .not-front.page-house-rules .img-text-container .lt-img-wrapper {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
  }
}
.not-front.page-more-tips-detail .img-text-container .lt-img-wrapper img, .not-front.page-cooking-methods-detail .img-text-container .lt-img-wrapper img, .not-front.page-nutrition-detail .img-text-container .lt-img-wrapper img, .not-front.page-terms .img-text-container .lt-img-wrapper img, .not-front.page-privacy .img-text-container .lt-img-wrapper img, .not-front.page-cookiespolicy .img-text-container .lt-img-wrapper img, .not-front.page-animal-welfare .img-text-container .lt-img-wrapper img, .not-front.page-house-rules .img-text-container .lt-img-wrapper img {
  width: 140px;
}
.not-front.page-more-tips-detail .img-text-container .rt-text-wrapper, .not-front.page-cooking-methods-detail .img-text-container .rt-text-wrapper, .not-front.page-nutrition-detail .img-text-container .rt-text-wrapper, .not-front.page-terms .img-text-container .rt-text-wrapper, .not-front.page-privacy .img-text-container .rt-text-wrapper, .not-front.page-cookiespolicy .img-text-container .rt-text-wrapper, .not-front.page-animal-welfare .img-text-container .rt-text-wrapper, .not-front.page-house-rules .img-text-container .rt-text-wrapper {
  flex: 1;
}
.not-front.page-more-tips-detail .t-heading, .not-front.page-cooking-methods-detail .t-heading, .not-front.page-nutrition-detail .t-heading, .not-front.page-terms .t-heading, .not-front.page-privacy .t-heading, .not-front.page-cookiespolicy .t-heading, .not-front.page-animal-welfare .t-heading, .not-front.page-house-rules .t-heading {
  color: #274120;
}
.not-front.page-more-tips-detail .content-bg-fff > p, .not-front.page-cooking-methods-detail .content-bg-fff > p, .not-front.page-nutrition-detail .content-bg-fff > p, .not-front.page-terms .content-bg-fff > p, .not-front.page-privacy .content-bg-fff > p, .not-front.page-cookiespolicy .content-bg-fff > p, .not-front.page-animal-welfare .content-bg-fff > p, .not-front.page-house-rules .content-bg-fff > p {
  margin-top: 30px;
  padding-right: 65px;
}
@media (max-width: 1080px) {
  .not-front.page-more-tips-detail .content-bg-fff > p, .not-front.page-cooking-methods-detail .content-bg-fff > p, .not-front.page-nutrition-detail .content-bg-fff > p, .not-front.page-terms .content-bg-fff > p, .not-front.page-privacy .content-bg-fff > p, .not-front.page-cookiespolicy .content-bg-fff > p, .not-front.page-animal-welfare .content-bg-fff > p, .not-front.page-house-rules .content-bg-fff > p {
    padding-right: calc(2px + (65 - 2) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-more-tips-detail .content-bg-fff > div, .not-front.page-cooking-methods-detail .content-bg-fff > div, .not-front.page-nutrition-detail .content-bg-fff > div, .not-front.page-terms .content-bg-fff > div, .not-front.page-privacy .content-bg-fff > div, .not-front.page-cookiespolicy .content-bg-fff > div, .not-front.page-animal-welfare .content-bg-fff > div, .not-front.page-house-rules .content-bg-fff > div {
  margin-top: 30px;
}
.not-front.page-more-tips-detail .content-bg-fff ul, .not-front.page-cooking-methods-detail .content-bg-fff ul, .not-front.page-nutrition-detail .content-bg-fff ul, .not-front.page-terms .content-bg-fff ul, .not-front.page-privacy .content-bg-fff ul, .not-front.page-cookiespolicy .content-bg-fff ul, .not-front.page-animal-welfare .content-bg-fff ul, .not-front.page-house-rules .content-bg-fff ul {
  list-style-type: initial;
  padding-left: 15px;
}
.not-front.page-more-tips-detail .content-bg-fff ul.pl-30, .not-front.page-cooking-methods-detail .content-bg-fff ul.pl-30, .not-front.page-nutrition-detail .content-bg-fff ul.pl-30, .not-front.page-terms .content-bg-fff ul.pl-30, .not-front.page-privacy .content-bg-fff ul.pl-30, .not-front.page-cookiespolicy .content-bg-fff ul.pl-30, .not-front.page-animal-welfare .content-bg-fff ul.pl-30, .not-front.page-house-rules .content-bg-fff ul.pl-30 {
  padding-left: 30px;
}
.not-front.page-more-tips-detail .content-bg-fff ul li, .not-front.page-cooking-methods-detail .content-bg-fff ul li, .not-front.page-nutrition-detail .content-bg-fff ul li, .not-front.page-terms .content-bg-fff ul li, .not-front.page-privacy .content-bg-fff ul li, .not-front.page-cookiespolicy .content-bg-fff ul li, .not-front.page-animal-welfare .content-bg-fff ul li, .not-front.page-house-rules .content-bg-fff ul li {
  padding-left: 15px;
  margin-top: 30px;
  line-height: 1.7;
}
.not-front.page-more-tips-detail .content-bg-fff ul li ul, .not-front.page-cooking-methods-detail .content-bg-fff ul li ul, .not-front.page-nutrition-detail .content-bg-fff ul li ul, .not-front.page-terms .content-bg-fff ul li ul, .not-front.page-privacy .content-bg-fff ul li ul, .not-front.page-cookiespolicy .content-bg-fff ul li ul, .not-front.page-animal-welfare .content-bg-fff ul li ul, .not-front.page-house-rules .content-bg-fff ul li ul {
  list-style-type: circle;
}
.not-front.page-more-tips-detail .content-bg-fff ul li p + p, .not-front.page-cooking-methods-detail .content-bg-fff ul li p + p, .not-front.page-nutrition-detail .content-bg-fff ul li p + p, .not-front.page-terms .content-bg-fff ul li p + p, .not-front.page-privacy .content-bg-fff ul li p + p, .not-front.page-cookiespolicy .content-bg-fff ul li p + p, .not-front.page-animal-welfare .content-bg-fff ul li p + p, .not-front.page-house-rules .content-bg-fff ul li p + p {
  margin-top: 30px;
}
.not-front.page-more-tips-detail .content-bg-fff, .not-front.page-nutrition-detail .content-bg-fff {
  padding: 30px 35px 30px 100px;
}
@media (max-width: 1080px) {
  .not-front.page-more-tips-detail .content-bg-fff, .not-front.page-nutrition-detail .content-bg-fff {
    padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(25px + (35 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-cooking-methods-detail .content-bg-fff {
  padding: 30px 90px;
}
@media (max-width: 1080px) {
  .not-front.page-cooking-methods-detail .content-bg-fff {
    padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(25px + (35 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-nutrition-detail .content-bg-fff > br {
  display: none;
}
.not-front.page-nutrition-detail .content-bg-fff > p {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 30px;
}
.not-front.page-nutrition-detail .content-bg-fff > .t-heading ~ .t-heading {
  margin-top: 40px;
}
.not-front.page-nutrition-detail .content-bg-fff > ul {
  margin-bottom: 30px;
}
.not-front.page-nutrition-detail .content-bg-fff > ul > li {
  padding-left: 10px;
}
.not-front.page-nutrition-detail .content-bg-fff > .t-heading + ul, .not-front.page-nutrition-detail .content-bg-fff > .t-heading + ul {
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 0;
}
@media (max-width: 1080px) {
  .not-front.page-nutrition-detail .content-bg-fff > .t-heading + ul, .not-front.page-nutrition-detail .content-bg-fff > .t-heading + ul {
    padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
    padding-left: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-nutrition-detail .content-bg-fff > .t-heading ~ p + ul {
  padding-right: 25px;
  padding-left: 50px;
}
@media (max-width: 1080px) {
  .not-front.page-nutrition-detail .content-bg-fff > .t-heading ~ p + ul {
    padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
    padding-left: calc(30px + (50 - 30) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-nutrition-detail .content-bg-fff > h2.t-heading ~ p {
  padding-right: 25px;
  padding-left: 30px;
  margin-bottom: 0;
}
@media (max-width: 1080px) {
  .not-front.page-nutrition-detail .content-bg-fff > h2.t-heading ~ p {
    padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
    padding-left: calc(15px + (30 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-animal-welfare .hash-link-wrapper {
  max-width: 780px;
}
.not-front.page-animal-welfare .hash-link-wrapper ul li a {
  background: #111111 !important;
}
.not-front.page-animal-welfare .content-bg-fff h2.t-heading {
  color: #2f2f2f;
}
.not-front.page-animal-welfare .content-bg-fff > p, .not-front.page-animal-welfare .content-bg-fff h2.t-heading {
  padding-right: 25px;
  padding-left: 50px;
}
@media (max-width: 1080px) {
  .not-front.page-animal-welfare .content-bg-fff > p, .not-front.page-animal-welfare .content-bg-fff h2.t-heading {
    padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
    padding-left: calc(15px + (50 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-animal-welfare .content-bg-fff ul {
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 70px;
}
@media (max-width: 1080px) {
  .not-front.page-animal-welfare .content-bg-fff ul {
    padding-left: calc(35px + (70 - 35) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-animal-welfare .content-bg-fff ul li {
  padding-left: 0;
  margin-top: 0;
}

.not-front.page-contact ::placeholder, .not-front.page-steakmate ::placeholder, .not-front.page-roastmate ::placeholder {
  color: #9c9c9c;
}
.not-front.page-contact .t-heading, .not-front.page-steakmate .t-heading, .not-front.page-roastmate .t-heading {
  color: #214f2b;
}
.not-front.page-contact .mla-worldwide-locations, .not-front.page-steakmate .mla-worldwide-locations, .not-front.page-roastmate .mla-worldwide-locations {
  padding-bottom: 100px;
}
.not-front.page-contact .form-wrapper, .not-front.page-steakmate .form-wrapper, .not-front.page-roastmate .form-wrapper {
  margin-bottom: 50px;
}
.not-front.page-contact .form-wrapper .contact-info, .not-front.page-steakmate .form-wrapper .contact-info, .not-front.page-roastmate .form-wrapper .contact-info {
  padding-left: 30px;
}
.not-front.page-contact .form-wrapper .contact-info .t-heading, .not-front.page-steakmate .form-wrapper .contact-info .t-heading, .not-front.page-roastmate .form-wrapper .contact-info .t-heading {
  margin-bottom: 10px;
}
.not-front.page-contact .form-wrapper .form-control button, .not-front.page-contact .form-wrapper .form-control a.btn, .not-front.page-steakmate .form-wrapper .form-control button, .not-front.page-steakmate .form-wrapper .form-control a.btn, .not-front.page-roastmate .form-wrapper .form-control button, .not-front.page-roastmate .form-wrapper .form-control a.btn {
  display: inline-block;
  padding: 9px 25px;
  background: #fec839;
  color: #274120;
  font-family: "Surt-Bold";
  border-radius: 25px;
  font-size: 18px;
  white-space: nowrap;
  margin-bottom: 20px;
  border: none;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  outline: none;
}
@media (max-width: 1920px) {
  .not-front.page-contact .form-wrapper .form-control button, .not-front.page-contact .form-wrapper .form-control a.btn, .not-front.page-steakmate .form-wrapper .form-control button, .not-front.page-steakmate .form-wrapper .form-control a.btn, .not-front.page-roastmate .form-wrapper .form-control button, .not-front.page-roastmate .form-wrapper .form-control a.btn {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
  }
}
.not-front.page-contact .form-wrapper .d-flex, .not-front.page-steakmate .form-wrapper .d-flex, .not-front.page-roastmate .form-wrapper .d-flex {
  justify-content: space-between;
}
.not-front.page-contact .form-wrapper .d-flex > div, .not-front.page-steakmate .form-wrapper .d-flex > div, .not-front.page-roastmate .form-wrapper .d-flex > div {
  flex: 0 0 48%;
}
@media (max-width: 710px) {
  .not-front.page-contact .form-wrapper .d-flex > div, .not-front.page-steakmate .form-wrapper .d-flex > div, .not-front.page-roastmate .form-wrapper .d-flex > div {
    flex: 0 0 100%;
  }
}
.not-front.page-contact .form-wrapper .d-flex > div label, .not-front.page-steakmate .form-wrapper .d-flex > div label, .not-front.page-roastmate .form-wrapper .d-flex > div label {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 30px;
}
.not-front.page-contact .form-wrapper .d-flex > div input, .not-front.page-contact .form-wrapper .d-flex > div select, .not-front.page-contact .form-wrapper .d-flex > div textarea, .not-front.page-steakmate .form-wrapper .d-flex > div input, .not-front.page-steakmate .form-wrapper .d-flex > div select, .not-front.page-steakmate .form-wrapper .d-flex > div textarea, .not-front.page-roastmate .form-wrapper .d-flex > div input, .not-front.page-roastmate .form-wrapper .d-flex > div select, .not-front.page-roastmate .form-wrapper .d-flex > div textarea {
  background-color: #f7f7f7;
  display: inline-block;
  box-shadow: none;
  border: none;
  outline: none;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  margin-bottom: 20px;
  width: calc(100% - 60px);
  border-radius: 30px;
  font-size: 18px;
  color: #9c9c9c;
}
@media (max-width: 1080px) {
  .not-front.page-contact .form-wrapper .d-flex > div input, .not-front.page-contact .form-wrapper .d-flex > div select, .not-front.page-contact .form-wrapper .d-flex > div textarea, .not-front.page-steakmate .form-wrapper .d-flex > div input, .not-front.page-steakmate .form-wrapper .d-flex > div select, .not-front.page-steakmate .form-wrapper .d-flex > div textarea, .not-front.page-roastmate .form-wrapper .d-flex > div input, .not-front.page-roastmate .form-wrapper .d-flex > div select, .not-front.page-roastmate .form-wrapper .d-flex > div textarea {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-contact .form-wrapper .d-flex > div select, .not-front.page-steakmate .form-wrapper .d-flex > div select, .not-front.page-roastmate .form-wrapper .d-flex > div select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 30px);
  background-position-y: 11px;
}
.not-front.page-contact .form-wrapper .d-flex > div textarea, .not-front.page-steakmate .form-wrapper .d-flex > div textarea, .not-front.page-roastmate .form-wrapper .d-flex > div textarea {
  height: 300px;
  resize: none;
  line-height: 1.5;
  padding-top: 20px;
}
@media (max-width: 710px) {
  .not-front.page-contact .form-wrapper .d-flex > div textarea, .not-front.page-steakmate .form-wrapper .d-flex > div textarea, .not-front.page-roastmate .form-wrapper .d-flex > div textarea {
    height: 200px;
  }
}

.not-front.page-steakmate .form-wrapper .d-flex,
.not-front.page-roastmate .form-wrapper .d-flex {
  align-items: flex-end;
}
.not-front.page-steakmate .form-wrapper .d-flex > div label.t-heading,
.not-front.page-roastmate .form-wrapper .d-flex > div label.t-heading {
  padding-left: 0;
}
.not-front.page-steakmate .form-wrapper .d-flex > div select,
.not-front.page-roastmate .form-wrapper .d-flex > div select {
  color: #000;
  background-image: url("../web-images/select-icon.svg");
  background-position-y: 20px;
}
.not-front.page-steakmate .form-wrapper .d-flex > div input#smThicknessSelect,
.not-front.page-roastmate .form-wrapper .d-flex > div input#smThicknessSelect {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  background: #f7f7f7;
  outline: none;
  padding: 0;
  margin-bottom: 33px;
}
.not-front.page-steakmate .form-wrapper .d-flex > div input#smThicknessSelect::-webkit-slider-thumb,
.not-front.page-roastmate .form-wrapper .d-flex > div input#smThicknessSelect::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #214f2b;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: none;
}
.not-front.page-steakmate .form-wrapper .d-flex > div input#smThicknessSelect::-moz-range-thumb,
.not-front.page-roastmate .form-wrapper .d-flex > div input#smThicknessSelect::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #214f2b;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: none;
}

.not-front.page-steakmate .lity-content, .not-front.page-roastmate .lity-content {
  border-radius: 30px;
  overflow: hidden;
}
@media (max-width: 710px) {
  .not-front.page-steakmate .lity-content, .not-front.page-roastmate .lity-content {
    border-radius: 20px;
  }
}
.not-front.page-steakmate .content-bg-fff#smOutput .screenshots, .not-front.page-roastmate .content-bg-fff#smOutput .screenshots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  .not-front.page-steakmate .content-bg-fff#smOutput .screenshots, .not-front.page-roastmate .content-bg-fff#smOutput .screenshots {
    justify-content: center;
  }
}
.not-front.page-steakmate .content-bg-fff#smOutput .screenshots img, .not-front.page-roastmate .content-bg-fff#smOutput .screenshots img {
  width: 300px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
}
@media (max-width: 1000px) {
  .not-front.page-steakmate .content-bg-fff#smOutput .screenshots img, .not-front.page-roastmate .content-bg-fff#smOutput .screenshots img {
    margin: 10px;
  }
}
.not-front.page-steakmate .content-bg-fff ul, .not-front.page-roastmate .content-bg-fff ul {
  padding-left: 30px;
  list-style: none;
  position: relative;
  z-index: 1;
}
@media (max-width: 710px) {
  .not-front.page-steakmate .content-bg-fff ul, .not-front.page-roastmate .content-bg-fff ul {
    padding-left: 15px;
  }
}
.not-front.page-steakmate .content-bg-fff ul:before, .not-front.page-roastmate .content-bg-fff ul:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #ccc;
  position: absolute;
  left: 15px;
  top: 0;
  z-index: -1;
  display: inline-block;
}
@media (max-width: 710px) {
  .not-front.page-steakmate .content-bg-fff ul:before, .not-front.page-roastmate .content-bg-fff ul:before {
    left: 0;
  }
}
.not-front.page-steakmate .content-bg-fff ul li, .not-front.page-roastmate .content-bg-fff ul li {
  margin-bottom: 20px;
  position: relative;
}
.not-front.page-steakmate .content-bg-fff ul li::before, .not-front.page-roastmate .content-bg-fff ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-clip: padding-box;
  background: #000;
  position: absolute;
  left: -17px;
  top: 7px;
  z-index: 0;
  display: inline-block;
}
.not-front.page-steakmate .content-bg-fff ul li span.flag, .not-front.page-roastmate .content-bg-fff ul li span.flag {
  font-family: "Surt-Bold";
}

.not-front.page-roastmate .form-wrapper .d-flex {
  align-items: flex-start;
}

#map {
  border-radius: 35px;
}

.slick-slider {
  padding-bottom: 75px;
  position: relative;
}
.slick-slider .retailer {
  background: #ffffff;
  padding: 5px 5px 10px 5px;
  border-radius: 35px;
  margin-top: 25px;
}
.slick-slider .retailer .retailer-logo img {
  border-radius: 35px;
}
.slick-slider .retailer .buy-online {
  padding-top: 14px;
  padding-bottom: 7px;
  text-align: center;
  min-height: 10px;
}
.slick-slider .retailer .buy-online a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  padding: 7px 25px;
  font-family: "Surt-Bold";
  border-radius: 18px;
  border: 2px solid #eeeeee;
  color: #274120;
}
.slick-slider .retailer .store-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slider .retailer .store-links a + a {
  margin-left: 10px;
}
.slick-slider .slick-arrow {
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: transparent;
  background: url("../web-images/where-to-buy/next-pre-buttons.png");
  outline: none;
  box-shadow: none;
}
.slick-slider .slick-arrow.slick-prev {
  left: calc(50% - 16px);
}
.slick-slider .slick-arrow.slick-next {
  left: calc(50% + 16px);
  background-position: 16px 0;
}

.retailers-tablist {
  margin-bottom: 10px;
}

.page-where-to-buy main {
  padding-bottom: 70px;
}
.page-where-to-buy .b-tab.active {
  padding: 0;
}
.page-where-to-buy .slick-slide > div {
  display: flex;
}
@media (max-width: 710px) {
  .page-where-to-buy .slick-slide > div {
    justify-content: space-between;
  }
}
@media (min-width: 711px) {
  .page-where-to-buy .slick-slide > div > div {
    flex: 0 0 23%;
    max-width: 240px;
  }
  .page-where-to-buy .slick-slide > div > div:nth-child(4n-2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .page-where-to-buy .slick-slide > div > div:nth-child(4n-1) {
    margin-right: 1%;
  }
}
@media (max-width: 710px) {
  .page-where-to-buy .slick-slide > div > div {
    flex: 0 0 45%;
    max-width: calc(100% - 10px);
  }
  .page-where-to-buy .slick-slide > div > div .retailer-logo img {
    width: 100%;
    object-fit: cover;
    height: calc(134px + (269 - 134) * ((100vw - 375px) / (710 - 375)));
  }
}

.page-search.not-front .banner-container {
  margin-bottom: 90px;
}
@media (max-width: 1920px) {
  .page-search.not-front .banner-container {
    margin-bottom: calc(20px + (90 - 20) * (100vw - 375px) / (1920 - 375));
  }
}
.page-search.not-front .t-heading {
  margin-bottom: 0;
  color: #214f2b;
}

.node-foodservice.not-front .loadmore button {
  color: #2f2f2f;
}
.node-foodservice.not-front.page-foodservice-recipies .card-main-wrapper .card-wrapper .card {
  display: none;
}
.node-foodservice.not-front.page-foodservice-recipies .card-main-wrapper .card-wrapper .card:nth-child(-n+8) {
  display: block;
}
.node-foodservice.not-front.page-foodservice-recipies .card-main-wrapper .card-wrapper .card .card--footer b span {
  text-transform: uppercase !important;
}

.not-front.page-bbq .page-banner .banner-container img {
  object-position: center center;
}
.not-front.page-bbq .recipes-tabs {
  padding: 0 45px;
  margin-bottom: 25px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq .recipes-tabs {
    margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
    padding: 0 calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq .recipes-tabs a {
  color: #607b2a !important;
}
.not-front.page-bbq .recipes-tabs a.active-link, .not-front.page-bbq .recipes-tabs a.active {
  background: #607b2a !important;
  color: #ffffff !important;
}
.not-front.page-bbq .bbq-intro h2.heading {
  font-size: 35px;
  font-family: "Surt-Bold";
}
@media (max-width: 1080px) {
  .not-front.page-bbq .bbq-intro h2.heading {
    font-size: calc(21px + (35 - 21) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq .bbq-intro p {
  font-size: 26px;
  font-family: "Surt-Regular";
  color: #58595b;
  margin-bottom: 15px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq .bbq-intro p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq .bbq-intro p .g-text-color {
  color: #5c7b35;
}
@media (max-width: 1080px) {
  .not-front.page-bbq .bbq-intro p .g-text-color {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq .section-ql {
  padding-top: 30px;
  padding-bottom: 20px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq .section-ql {
    padding-top: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section#Meat-grilling-calculator {
  margin-bottom: 0;
}
.not-front.page-bbq section {
  padding: 2px;
  border-radius: 25px;
  background-clip: padding-box;
  margin-bottom: 50px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section {
    margin-bottom: 0px;
    padding: 0px;
  }
}
.not-front.page-bbq section#get-grilling-with-master-chef-tarek, .not-front.page-bbq section#grilling-recipes {
  background-color: #fdf3da;
}
.not-front.page-bbq section#get-grilling-with-master-chef-tarek .card-main-wrapper, .not-front.page-bbq section#grilling-recipes .card-main-wrapper {
  padding: 0 15px;
}
.not-front.page-bbq section#get-grilling-with-master-chef-tarek .loadmore button, .not-front.page-bbq section#grilling-recipes .loadmore button {
  padding-left: 30px;
  padding-right: 30px;
}
.not-front.page-bbq section#marinades-to-try {
  background-color: #eff5e5;
}
.not-front.page-bbq section#marinades-to-try .card-main-wrapper {
  padding: 0 15px;
}
.not-front.page-bbq section .section-header {
  background: #5f7b29;
  border-radius: 25px;
  background-clip: padding-box;
  padding: 30px 45px;
  margin-bottom: 25px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .section-header {
    margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
    padding: calc(13px + (30 - 13) * ((100vw - 375px) / (1080 - 375))) calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 767px) {
  .not-front.page-bbq section .section-header {
    text-align: center;
  }
}
.not-front.page-bbq section .section-header * {
  color: #ffffff;
}
.not-front.page-bbq section .section-header h2 {
  font-family: "Surt-Bold";
  font-size: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .section-header h2 {
    font-size: calc(11px + (35 - 11) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .section-header p {
  font-size: 26px;
  font-family: "Surt-Regular";
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .section-header p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .section-header p a {
  text-decoration: none;
  color: #ffbf00;
}
.not-front.page-bbq section .form-wrapper {
  /*margin-bottom: 50px;*/
}
.not-front.page-bbq section .d-flex {
  justify-content: space-between;
  margin-bottom: 4%;
}
.not-front.page-bbq section .d-flex > div {
  flex: 0 0 48%;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .d-flex > div {
    flex: 0 0 100%;
  }
}
.not-front.page-bbq section .d-flex > div label {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}
.not-front.page-bbq section .d-flex input, .not-front.page-bbq section .d-flex select, .not-front.page-bbq section .d-flex textarea {
  background-color: #f7f7f7;
  display: inline-block;
  box-shadow: none;
  border: none;
  outline: none;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  margin-bottom: 20px;
  width: calc(100% - 60px);
  border-radius: 30px;
  font-size: 18px;
  color: #9c9c9c;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .d-flex input, .not-front.page-bbq section .d-flex select, .not-front.page-bbq section .d-flex textarea {
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .d-flex select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000;
  background-image: url(../web-images/select-icon.svg);
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 30px);
  /*background-position-y: 11px;*/
}
.not-front.page-bbq section .d-flex textarea {
  height: 300px;
  resize: none;
  line-height: 1.5;
  padding-top: 20px;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .d-flex textarea {
    height: 200px;
  }
}
.not-front.page-bbq section .d-flex input#smThicknessSelect {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  background: #f7f7f7;
  outline: none;
  padding: 0;
  margin-bottom: 33px;
}
.not-front.page-bbq section .d-flex input#smThicknessSelect::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  font-size: 24px;
  width: 16px;
  height: 16px;
  background: #214f2b;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: none;
}
.not-front.page-bbq section .d-flex input#smThicknessSelect::-moz-range-thumb {
  font-size: 24px;
  width: 16px;
  height: 16px;
  background: #214f2b;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: none;
}
.not-front.page-bbq section .form-control a.btn {
  display: inline-block;
  padding: 9px 25px;
  background: #f9a832;
  color: #fff;
  font-family: "Surt-Bold";
  border-radius: 25px;
  font-size: 18px;
  white-space: nowrap;
  border: none;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  outline: none;
}
.not-front.page-bbq section .content-bg-fff {
  font-size: 24px;
  background: #ffffff;
  padding: 30px 35px;
  border-radius: 30px;
  background-clip: padding-box;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .not-front.page-bbq section .content-bg-fff {
    margin-bottom: 25px;
  }
}
.not-front.page-bbq section .content-bg-fff#smOutput .screenshots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  .not-front.page-bbq section .content-bg-fff#smOutput .screenshots {
    justify-content: center;
  }
}
.not-front.page-bbq section .content-bg-fff#smOutput .screenshots img {
  width: 300px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
}
@media (max-width: 1000px) {
  .not-front.page-bbq section .content-bg-fff#smOutput .screenshots img {
    margin: 10px;
  }
}
.not-front.page-bbq section .content-bg-fff ul {
  padding-left: 30px;
  list-style: none;
  position: relative;
  z-index: 1;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .content-bg-fff ul {
    padding-left: 15px;
  }
}
.not-front.page-bbq section .content-bg-fff ul:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #ccc;
  position: absolute;
  left: 15px;
  top: 0;
  z-index: -1;
  display: inline-block;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .content-bg-fff ul:before {
    left: 0;
  }
}
.not-front.page-bbq section .content-bg-fff ul li {
  margin-bottom: 20px;
  position: relative;
}
.not-front.page-bbq section .content-bg-fff ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-clip: padding-box;
  background: #000;
  position: absolute;
  left: -17px;
  top: 7px;
  z-index: 0;
  display: inline-block;
}
.not-front.page-bbq section .content-bg-fff ul li span.flag {
  font-family: "Surt-Bold";
}
.not-front.page-bbq section .t-heading {
  font-size: 24px;
  line-height: 1.5;
  font-family: "Surt-Bold";
  color: #214f2b;
  margin-bottom: 30px;
}
.not-front.page-bbq section .step-heading {
  color: #ffffff;
  font-size: 24px;
  font-family: "Surt-Bold";
  display: inline-block;
  background: #f9a832;
  border-radius: 25px;
  overflow: hidden;
  margin: 20px 0 35px 30px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .step-heading {
    margin-top: calc(1px + (20 - 1) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
    margin-left: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
    font-size: calc(8px + (24 - 8) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .step-heading span {
  padding: 7px 15px;
  display: inline-block;
}
.not-front.page-bbq section .step-heading span:nth-last-of-type(1) {
  padding-right: 50px;
  background: #c0672c;
}
.not-front.page-bbq section .tips-heading {
  font-size: 33px;
  font-family: "Surt-Bold";
  color: #c0672d;
  margin-bottom: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .tips-heading {
    font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(17px + (35 - 17) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .tips-content {
  justify-content: space-between;
}
.not-front.page-bbq section .tips-content > div {
  max-width: calc(50% - 15px);
  flex: 0 0 50%;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .tips-content > div {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
}
.not-front.page-bbq section .tips-content > div img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .tips-content > div img {
    height: calc(128px + (300 - 128) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .tips-content > div img {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.not-front.page-bbq section .tips-content > div h2 {
  font-family: "Surt-Bold";
  font-size: 33px;
  color: #607c29;
  padding: 25px 0 20px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .tips-content > div h2 {
    font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
    padding: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375))) 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .tips-content > div h2 br {
    display: none;
  }
}
.not-front.page-bbq section .tips-content > div p {
  font-size: 26px;
  font-family: "Surt-Regular";
  color: #58595b;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .tips-content > div p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .tips-content > div p {
    padding-right: 15px;
  }
  .not-front.page-bbq section .tips-content > div p br {
    display: none;
  }
}
.not-front.page-bbq section#learn-the-basics > div {
  background: #fff;
  border-radius: 35px;
  margin-bottom: 30px;
}
.not-front.page-bbq section#learn-the-basics > div:nth-of-type(1) {
  padding-bottom: 30px;
}
.not-front.page-bbq section#learn-the-basics > div:nth-of-type(2) {
  padding-top: 20px;
}
@media (min-width: 1920px) {
  .not-front.page-bbq section#learn-the-basics > div:nth-of-type(2) .bg-card-wrapper .bg-card-header picture img {
    height: 380px;
  }
}
.not-front.page-bbq section#learn-the-basics > div#pro-tips {
  padding: 20px 1px 0px;
  overflow: hidden;
  margin-bottom: 0;
}
.not-front.page-bbq section .accordionWrapper {
  padding: 0 30px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper {
    padding: 0 calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemHeading {
  font-size: 26px;
  font-family: "Surt-Bold";
  color: #575756;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemHeading {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemHeading:after {
  content: "+";
  display: inline-block;
  position: relative;
  font-family: "Surt-Regular";
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  padding: 20px;
  display: block;
  transform: scaleY(0);
  width: 100%;
  overflow-x: auto;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent {
    padding: 10px;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
  padding: 30px 0;
  align-items: flex-start;
  justify-content: center;
  align-items: stretch;
  direction: ltr;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
    padding: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
    justify-content: flex-start;
  }
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap picture, .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap img {
    width: 100%;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div {
  max-width: calc(25% - 1px);
  flex: 0 0 25%;
  border-right: 1px solid #adc154;
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 1px);
  }
}
@media (min-width: 711px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(4n) {
    border-right: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(3n) {
    border-right: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(4), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(5), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(6) {
    border-top: 1px solid #adc154;
    border-bottom: 1px solid #adc154;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(n+10) {
    border-top: 1px solid #adc154;
  }
}
@media (min-width: 711px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(n+5) {
    border-top: 1px solid #adc154;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .text-wrap {
  font-size: 15px;
  font-family: "Surt-Bold";
  color: #2d4024;
  text-align: center;
  padding: 0 10px 10px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .text-wrap {
    font-size: calc(8px + (15 - 8) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .img-wrap {
  width: 156px;
  height: 156px;
  margin: 0 auto;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .img-wrap {
    width: calc(80px + (156 - 80) * ((100vw - 375px) / (1080 - 375)));
    height: calc(75px + (156 - 75) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div .img-wrap {
  background: url("../web-images/bbq/lamb-accordion.png") no-repeat;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div .img-wrap {
    background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(1) .img-wrap {
  background-position: -20px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(1) .img-wrap {
    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(2) .img-wrap {
  background-position: -254px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(2) .img-wrap {
    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(3) .img-wrap {
  background-position: -522px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(3) .img-wrap {
    background-position: calc(-205px + (-522 - (-205)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(4) .img-wrap {
  background-position: -772px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(4) .img-wrap {
    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(5) .img-wrap {
  background-position: -20px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(5) .img-wrap {
    background-position: calc(0px + (-20 - (0)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(6) .img-wrap {
  background-position: -254px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(6) .img-wrap {
    background-position: calc(-105px + (-254 - (-105)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(7) .img-wrap {
  background-position: -522px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(7) .img-wrap {
    background-position: calc(-204px + (-522 - (-204)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(8) .img-wrap {
  background-position: -772px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(8) .img-wrap {
    background-position: calc(-300px + (-772 - (-300)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(9) .img-wrap {
  background-position: -20px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(9) .img-wrap {
    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(10) .img-wrap {
  background-position: -254px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(10) .img-wrap {
    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(11) .img-wrap {
  width: 225px;
  background-position: -490px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(11) .img-wrap {
    width: calc(100px + (225 - (100)) * ((100vw - 375px) / (1080 - 375)));
    background-position: calc(-195px + (-490 - (-195)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(12) .img-wrap {
  background-position: -772px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(12) .img-wrap {
    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div .img-wrap {
  width: 195px;
  background: url("../web-images/bbq/beef-accordion.png") no-repeat;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div .img-wrap {
    width: calc(95px + (195 - 95) * ((100vw - 375px) / (1080 - 375)));
    background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (min-width: 711px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) {
    border-bottom: 1px solid #adc154;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) {
  border-top: 0;
}
@media (min-width: 711px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) {
    border-left: 1px solid #adc154;
  }
}
@media (max-width: 710px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10), .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) {
    border-bottom: 1px solid #adc154;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(1) .img-wrap {
  background-position: -20px -20px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(1) .img-wrap {
    background-position: calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375))) calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(2) .img-wrap {
  background-position: -254px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(2) .img-wrap {
    background-position: calc(-90px + (-254 - (-90)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(3) .img-wrap {
  width: 215px;
  background-position: -507px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(3) .img-wrap {
    width: calc(95px + (215 - (95)) * ((100vw - 375px) / (1080 - 375)));
    background-position: calc(-200px + (-507 - (-200)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(4) .img-wrap {
  background-position: -745px 0;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(4) .img-wrap {
    background-position: calc(-295px + (-745 - (-295)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(5) .img-wrap {
  background-position: 2px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(5) .img-wrap {
    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(6) .img-wrap {
  background-position: -254px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(6) .img-wrap {
    background-position: calc(-95px + (-254 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(7) .img-wrap {
  background-position: -506px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(7) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(8) .img-wrap {
  background-position: -762px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(8) .img-wrap {
    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9) .img-wrap {
  background-position: 2px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9) .img-wrap {
    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10) .img-wrap {
  background-position: -245px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10) .img-wrap {
    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) .img-wrap {
  background-position: -506px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12) .img-wrap {
  background-position: -762px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12) .img-wrap {
    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) .img-wrap {
  background-position: -245px -630px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) .img-wrap {
    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) .img-wrap {
  background-position: -506px -630px;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .accordionWrapper .accordionItem.open .accordionItemHeading:after {
  content: "-";
  color: #adc154;
}
.not-front.page-bbq section .accordionWrapper .accordionItem.open .accordionItemHeading {
  border-color: #adc154;
}
.not-front.page-bbq section .accordionWrapper .accordionItem.open .accordionItemContent {
  transform: scaleY(1);
  height: auto;
}
.not-front.page-bbq section .pro-tips-item {
  margin-bottom: 30px;
  border-radius: 35px;
  overflow: hidden;
  padding: 45px 30px;
  background: #f1f8e3;
}
@media (max-width: 1080px) {
  .not-front.page-bbq section .pro-tips-item {
    padding: calc(20px + (45 - 20) * ((100vw - 375px) / (1080 - 375))) calc(15px + (30 - 15) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-bbq section .pro-tips-item.while-you-grill {
  background: #fef5dc;
}
.not-front.page-bbq section .pro-tips-item.after-grilling {
  margin-bottom: 0;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #274120;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 1025px) and (max-width: 1920px) {
  .bg-card-wrapper .bg-card-header picture img {
    object-fit: contain;
    height: calc(380px + (470 - 380) * (100vw - 1025px)/(1920 - 1025));
  }
}

.not-front.page-thisramadan .recipes-tabs {
  padding: 0 45px;
  margin-bottom: 25px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan .recipes-tabs {
    margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
    padding: 0 calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan .bbq-intro h2.heading {
  font-size: 35px;
  font-family: "Surt-Bold";
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan .bbq-intro h2.heading {
    font-size: calc(21px + (35 - 21) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan .bbq-intro p {
  font-size: 26px;
  font-family: "Surt-Regular";
  color: #58595b;
  margin-bottom: 15px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan .bbq-intro p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan .bbq-intro p.g-text-color {
  color: #5c7b35;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan .bbq-intro p.g-text-color {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan .section-ql {
  padding-top: 30px;
  padding-bottom: 20px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan .section-ql {
    padding-top: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section {
  padding: 2px;
  border-radius: 25px;
  background-clip: padding-box;
  margin-bottom: 50px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section {
    margin-bottom: calc(25px + (50 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section#Cuts-for-popular-Ramadan-dishes, .not-front.page-thisramadan section#grilling-recipes {
  background: #fdf3da;
}
.not-front.page-thisramadan section#Cuts-for-popular-Ramadan-dishes .card-main-wrapper, .not-front.page-thisramadan section#grilling-recipes .card-main-wrapper {
  padding: 0 15px;
}
.not-front.page-thisramadan section#Cuts-for-popular-Ramadan-dishes .loadmore button, .not-front.page-thisramadan section#grilling-recipes .loadmore button {
  padding-left: 30px;
  padding-right: 30px;
}
.not-front.page-thisramadan section#marinades-to-try {
  background-color: #eff5e5;
}
.not-front.page-thisramadan section#marinades-to-try .card-main-wrapper {
  padding: 0 15px;
}
.not-front.page-thisramadan section .section-header {
  background-color: #274120;
  border-radius: 25px;
  background-clip: padding-box;
  padding: 30px 45px;
  margin-bottom: 25px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .section-header {
    margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
    padding: calc(13px + (30 - 13) * ((100vw - 375px) / (1080 - 375))) calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 767px) {
  .not-front.page-thisramadan section .section-header {
    text-align: center;
  }
}
.not-front.page-thisramadan section .section-header * {
  color: #ffffff;
}
.not-front.page-thisramadan section .section-header h2 {
  font-family: "Surt-Bold";
  font-size: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .section-header h2 {
    font-size: calc(11px + (35 - 11) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .section-header p {
  font-size: 26px;
  font-family: "Surt-Regular";
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .section-header p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .section-header p a {
  text-decoration: none;
  color: #ffbf00;
}
.not-front.page-thisramadan section .step-heading {
  color: #ffffff;
  font-size: 24px;
  font-family: "Surt-Bold";
  display: inline-block;
  background: #adc154;
  border-radius: 25px;
  overflow: hidden;
  margin: 20px 0 35px 30px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .step-heading {
    margin-top: calc(1px + (20 - 1) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
    margin-left: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
    font-size: calc(8px + (24 - 8) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .step-heading span {
  padding: 7px 15px;
  display: inline-block;
}
.not-front.page-thisramadan section .step-heading span:nth-last-of-type(1) {
  padding-right: 50px;
  background: #5f7b29;
}
.not-front.page-thisramadan section .tips-heading {
  font-size: 33px;
  font-family: "Surt-Bold";
  color: #7a121a;
  margin-bottom: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .tips-heading {
    font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(17px + (35 - 17) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .tips-content {
  justify-content: space-between;
}
.not-front.page-thisramadan section .tips-content > div {
  max-width: calc(50% - 15px);
  flex: 0 0 50%;
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .tips-content > div {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
}
.not-front.page-thisramadan section .tips-content > div img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 35px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .tips-content > div img {
    height: calc(128px + (300 - 128) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .tips-content > div img {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.not-front.page-thisramadan section .tips-content > div h2 {
  font-family: "Surt-Bold";
  font-size: 33px;
  color: #607c29;
  padding: 25px 0 20px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .tips-content > div h2 {
    font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
    padding: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375))) 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .tips-content > div h2 br {
    display: none;
  }
}
.not-front.page-thisramadan section .tips-content > div p {
  font-size: 26px;
  font-family: "Surt-Regular";
  color: #58595b;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .tips-content > div p {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .tips-content > div p {
    padding-right: 15px;
  }
  .not-front.page-thisramadan section .tips-content > div p br {
    display: none;
  }
}
.not-front.page-thisramadan section#learn-the-basics > div {
  background: #fff;
  border-radius: 35px;
  margin-bottom: 30px;
}
.not-front.page-thisramadan section#learn-the-basics > div:nth-of-type(1) {
  padding-bottom: 30px;
}
.not-front.page-thisramadan section#learn-the-basics > div:nth-of-type(2) {
  padding-top: 20px;
}
@media (min-width: 1920px) {
  .not-front.page-thisramadan section#learn-the-basics > div:nth-of-type(2) .bg-card-wrapper .bg-card-header picture img {
    height: 380px;
  }
}
.not-front.page-thisramadan section#learn-the-basics > div#pro-tips {
  padding: 20px 1px 0px;
  overflow: hidden;
  margin-bottom: 0;
}
.not-front.page-thisramadan section .accordionWrapper {
  padding: 0 30px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper {
    padding: 0 calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemHeading {
  font-size: 26px;
  font-family: "Surt-Bold";
  color: #575756;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemHeading {
    font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemHeading:after {
  content: "+";
  display: inline-block;
  position: relative;
  font-family: "Surt-Regular";
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  padding: 20px;
  display: block;
  transform: scaleY(0);
  width: 100%;
  overflow-x: auto;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent {
    padding: 10px;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
  padding: 30px 0;
  align-items: flex-start;
  justify-content: center;
  align-items: stretch;
  direction: ltr;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
    padding: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap {
    justify-content: flex-start;
  }
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap picture, .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap img {
    width: 100%;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div {
  max-width: calc(25% - 1px);
  flex: 0 0 25%;
  border-right: 1px solid #adc154;
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 1px);
  }
}
@media (min-width: 711px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(4n) {
    border-right: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(3n) {
    border-right: 0;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(4), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(5), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(6) {
    border-top: 1px solid #adc154;
    border-bottom: 1px solid #adc154;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(n+10) {
    border-top: 1px solid #adc154;
  }
}
@media (min-width: 711px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div:nth-child(n+5) {
    border-top: 1px solid #adc154;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .text-wrap {
  font-size: 15px;
  font-family: "Surt-Bold";
  color: #2d4024;
  text-align: center;
  padding: 0 10px 10px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .text-wrap {
    font-size: calc(8px + (15 - 8) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .img-wrap {
  width: 156px;
  height: 156px;
  margin: 0 auto;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap > div .img-wrap {
    width: calc(80px + (156 - 80) * ((100vw - 375px) / (1080 - 375)));
    height: calc(75px + (156 - 75) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div .img-wrap {
  background: url("../web-images/bbq/lamb-accordion.png") no-repeat;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div .img-wrap {
    background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(1) .img-wrap {
  background-position: -20px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(1) .img-wrap {
    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(2) .img-wrap {
  background-position: -254px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(2) .img-wrap {
    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(3) .img-wrap {
  background-position: -522px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(3) .img-wrap {
    background-position: calc(-205px + (-522 - (-205)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(4) .img-wrap {
  background-position: -772px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(4) .img-wrap {
    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(5) .img-wrap {
  background-position: -20px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(5) .img-wrap {
    background-position: calc(0px + (-20 - (0)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(6) .img-wrap {
  background-position: -254px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(6) .img-wrap {
    background-position: calc(-105px + (-254 - (-105)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(7) .img-wrap {
  background-position: -522px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(7) .img-wrap {
    background-position: calc(-204px + (-522 - (-204)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(8) .img-wrap {
  background-position: -772px -195px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(8) .img-wrap {
    background-position: calc(-300px + (-772 - (-300)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(9) .img-wrap {
  background-position: -20px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(9) .img-wrap {
    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(10) .img-wrap {
  background-position: -254px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(10) .img-wrap {
    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(11) .img-wrap {
  width: 225px;
  background-position: -490px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(11) .img-wrap {
    width: calc(100px + (225 - (100)) * ((100vw - 375px) / (1080 - 375)));
    background-position: calc(-195px + (-490 - (-195)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(12) .img-wrap {
  background-position: -772px -390px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.lamb-cuts > div:nth-of-type(12) .img-wrap {
    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div .img-wrap {
  width: 195px;
  background: url("../web-images/bbq/beef-accordion.png") no-repeat;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div .img-wrap {
    width: calc(95px + (195 - 95) * ((100vw - 375px) / (1080 - 375)));
    background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
  }
}
@media (min-width: 711px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) {
    border-bottom: 1px solid #adc154;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) {
  border-top: 0;
}
@media (min-width: 711px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) {
    border-left: 1px solid #adc154;
  }
}
@media (max-width: 710px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10), .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) {
    border-bottom: 1px solid #adc154;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(1) .img-wrap {
  background-position: -20px -20px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(1) .img-wrap {
    background-position: calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375))) calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(2) .img-wrap {
  background-position: -254px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(2) .img-wrap {
    background-position: calc(-90px + (-254 - (-90)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(3) .img-wrap {
  width: 215px;
  background-position: -507px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(3) .img-wrap {
    width: calc(95px + (215 - (95)) * ((100vw - 375px) / (1080 - 375)));
    background-position: calc(-200px + (-507 - (-200)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(4) .img-wrap {
  background-position: -745px 0;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(4) .img-wrap {
    background-position: calc(-295px + (-745 - (-295)) * ((100vw - 375px) / (1080 - 375))) 5px;
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(5) .img-wrap {
  background-position: 2px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(5) .img-wrap {
    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(6) .img-wrap {
  background-position: -254px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(6) .img-wrap {
    background-position: calc(-95px + (-254 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(7) .img-wrap {
  background-position: -506px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(7) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(8) .img-wrap {
  background-position: -762px -210px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(8) .img-wrap {
    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9) .img-wrap {
  background-position: 2px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(9) .img-wrap {
    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10) .img-wrap {
  background-position: -245px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(10) .img-wrap {
    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) .img-wrap {
  background-position: -506px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(11) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12) .img-wrap {
  background-position: -762px -430px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(12) .img-wrap {
    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) .img-wrap {
  background-position: -245px -630px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(13) .img-wrap {
    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) .img-wrap {
  background-position: -506px -630px;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .accordionWrapper .accordionItem .accordionItemContent .content-wrap.beef-cuts > div:nth-of-type(14) .img-wrap {
    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem.open .accordionItemHeading:after {
  content: "-";
  color: #adc154;
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem.open .accordionItemHeading {
  border-color: #adc154;
}
.not-front.page-thisramadan section .accordionWrapper .accordionItem.open .accordionItemContent {
  transform: scaleY(1);
  height: auto;
}
.not-front.page-thisramadan section .pro-tips-item {
  margin-bottom: 30px;
  border-radius: 35px;
  overflow: hidden;
  padding: 45px 30px;
  background: #f1f8e3;
}
@media (max-width: 1080px) {
  .not-front.page-thisramadan section .pro-tips-item {
    padding: calc(20px + (45 - 20) * ((100vw - 375px) / (1080 - 375))) calc(15px + (30 - 15) * ((100vw - 375px) / (1080 - 375)));
    margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));
  }
}
.not-front.page-thisramadan section .pro-tips-item.while-you-grill {
  background: #fef5dc;
}
.not-front.page-thisramadan section .pro-tips-item.after-grilling {
  margin-bottom: 0;
}
.not-front .ramadan-recipies-paragraph {
  margin-top: 25px;
}
.not-front .ramadan-dishes-paragraph {
  margin: 0 45px;
  margin-bottom: 25px;
}
.not-front section#tip-videos {
  background: #fdf3da;
}
.not-front section#tip-videos .tip-videos-paragraph {
  margin-bottom: 3rem;
  padding: 0 25px;
}
.not-front section#tip-videos .vid-wrap {
  padding: 0 25px;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #274120;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page-bbq .bg-card-wrapper .bg-card-header picture img {
  height: 380px;
}
@media (max-width: 1080px) {
  .page-bbq .bg-card-wrapper .bg-card-header picture img {
    height: calc(128px + (380 - 128) * ((100vw - 375px) / (1080 - 375)));
  }
}

.rmdan_dishes_bg {
  text-align: center;
  background: transparent;
}

.tip-videos-paragraph {
  margin-bottom: 3rem;
}

section#Ramadan-recipes {
  background: #fdf3da;
}