.top-header {
    @media (max-width: 710px) {
        display: none;
    }
    .top-header--links {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        z-index: 100;
        height: 32px;
        li {
            & + li {
                margin-left: 8px;
            }
            a {
                font-size: 0;
                width: 18px;
                height: 18px;
                color: $gray;
                // .node-foodservice:not(.page-resource-centre,.page-farm,.page-australian-goat) & ,
                // .node-butchery &, .node-useful-tools  &,
                // .page-cuts-by-protien &, .page-cooking-style &, .page-meat-cuts & {
                //     color: $white;
                // }
                text-decoration: none;
                display: inline-block;
                background-image: url("../web-images/icons.png");
                //  .node-foodservice:not(.page-resource-centre,.page-farm,.page-australian-goat) &,
                //  .node-butchery &, .node-useful-tools  & ,
                //   .page-cuts-by-protien &, .page-cooking-style &, .page-meat-cuts & {
                //     background-image: url("../web-images/icons-white.png"); 
                //  }
                background-repeat: no-repeat;
                &.fb {
                    background-position: 0 0;
                }
                &.tw {
                    background-position: -24px 0;
                }
                &.in {
                    background-position: -48px 0;
                }
            }
            &.togglelang a {
               font-family: $Ar; 
                font-size: 19px;
                width: auto;
                height: auto;
                background-image: none !important;
            }
        }
    }
    body.open-navigation & {
      background: $white;
      position: relative;
      z-index: 100;
    }
}


header {
    .mob-header {
        display: none;
        @media (max-width: 710px) {
            display: block;
            ul {
                align-items: center;
            }
            li a {
                color: $gray;
                text-decoration: none;
                display: inline-block;
                margin-right: 18px;
            }
            .search-button button {
                background: none;
                border: none;
                box-shadow: none;
                &:focus {
                    outline: none;
                }
                margin-right: 18px;
                margin-top: 5px;
            }
        }
    }
    position: relative;
    background: none;
    transition: all ease 0.5s;
    z-index: 100;
    &.menu-open {
        background: $white;
    }
    .main-navigation--wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 710px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .logo {
             img {
                max-width: auto;
                width: 300px;
                margin-right: 5px;
                @media (max-width: 1920px) {
                  width: calc(157px + (300 - 157) * (100vw - 375px)/(1920 - 375));
                }
                @media (max-width: 374px) {
                width: 157px;
                }
            }
        }
        .search-form {
            position: relative;

            &:before {
                content: "";
                width: 17px;
                height: 17px;
                background-image: url("../web-images/search.png");
                background-repeat: no-repeat;
                position: absolute;
                left: 15px;
                top: 11px;
                display: inline-block;
            }
            input {
                width: 408px;
                height: 40px;
                line-height: 40px;
                background: #dadada;
                border-radius: 40px;
                border: none;
                padding: 0 20px 0 40px;
                color: $white;
                font-size: $f19;
                &::placeholder {
                    color: $white;
                }
                @media (min-width: 711px) and (max-width: 1024px) {
                  width: calc(
                        250px + (400 - 250) * ((100vw - 711px) / (1024 - 711))
                    );
                }
                @media (max-width: 1920px) {
                    font-size: calc(
                        16px + (19 - 16) * ((100vw - 375px) / (1920 - 375))
                    );
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            button {
              @media (min-width: 711px) {
                display: none;
                pointer-events: none;
              }
              
            }
            @media (max-width: 710px) {
                order: 2;
                flex: 0 0 100%;
                height: 0;
                overflow: hidden;
                transition: all ease 0.45s;
                input {
                    width: calc(
                        278px + (603 - 278) * ((100vw - 375px) / (710 - 375))
                    );
                }
                button {
                    display: none;
                }
                &.search-open {
                    height: auto;
                    overflow: visible;
                }
            }
        }
    }
    .explore {
        button {
            position: relative;
            width: 160px;
            height: 40px;
            line-height: 40px;
            font-size: $f19;
            font-family: $SB;
            background: $white;
            color: $green;
            .node-foodservice &, 
            .node-butchery & {
                color: #000;
            }
            border: none;
            cursor: pointer;
            border-radius: 55px;
            transition: all ease 0.45s;
            @media (min-width: 711px) and (max-width: 1920px) {
                font-size: calc(
                    16px + (19 - 16) * ((100vw - 375px) / (1920 - 375))
                );
                box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);
            }
            @media (max-width: 710px) {
                width: auto;
                font-size: 0;
                border-radius: 0;
                padding: 0 10px;
                background: transparent;
            }
            span {
                font-size: 0;
                width: 1px;
                height: 20px;
                background: $green;
                .node-foodservice &, 
                .node-butchery & {
                    background: #000;
                }
                position: absolute;
                right: 35px;
                top: 13px;
                @media (min-width: 711px) and (max-width: 1920px) {
                    height: calc(
                        12px + (16 - 12) * ((100vw - 375px) / (1920 - 375))
                    );
                }
                & + span {
                    transform: rotate(90deg);
                }
                @media (max-width: 710px) {
                    right: 8px;
                    height: 15px;
                    top: 5px;
                }
            }
            &.menu-open {
                span {
                    visibility: hidden;
                    & + span {
                        visibility: visible;
                    }
                }
            }
        }
    }
    .main-navigation--menu {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 0);
        background: $white;
        padding: 0;
        border-radius: 0 0 25px 25px;
        height: 0;
        transition: all ease 0.45s;
        overflow: hidden;
        &.menu-open {
            height: auto;
            padding: 50px 0;
            overflow: visible;
            @media (max-width: 710px) {
                padding: 40px 0;
            }
        }
        max-width: 100%;
        ul {
            &.menu {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li {
                    a {
                        color: #5c5c5c;
                        font-size: $f16;
                        @media (max-width: 1920px) {
                            font-size: calc(
                                12px + (16 - 12) *
                                    ((100vw - 375px) / (1920 - 375))
                            );
                        }

                        font-family: $SR;
                        text-decoration: none;
                    }
                    @media (max-width: 710px) {
                        flex: 0 0 100%;
                        margin-bottom: 25px;
                        a {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                        }
                    }
                }
            }
            &.contact-us {
                padding-top: 30px;

                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                @media (max-width: 710px) {
                    padding-top: 20px;
                    justify-content: center;
                }
                li {
                    color: $green;
                    font-family: $SR;
                    font-size: $f16;
                    @media (max-width: 1920px) {
                        font-size: calc(
                            12px + (16 - 12) * ((100vw - 375px) / (1920 - 375))
                        );
                    }

                    & + li {
                        margin-left: 5px;
                    }
                    a {
                        color: inherit;
                        text-decoration: none;
                        font-family: inherit;
                    }
                }
            }
        }
    }
}
