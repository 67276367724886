.not-front {
  &.page-terms,
  &.page-privacy,
  &.page-cookiespolicy {
   .content-bg-fff a {
      color: #214f2b;
    }

  }
  &.page-more-tips-detail,
  &.page-cooking-methods-detail,
  &.page-nutrition-detail,
  &.page-terms,
  &.page-privacy,
  &.page-cookiespolicy,
  &.page-animal-welfare,
  &.page-house-rules {
    h2.heading {
      line-height: 1.5;
      margin-bottom: 50px;
    }
    
    .hash-link-wrapper {
      display: inline-block;
      margin-bottom: 30px;
      ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        li {
          margin-bottom: 10px;
          margin-right: 10px;
          
           a {
            display: inline-block;
            font-family: $SB;
            white-space: nowrap;
            text-decoration: none;
            color: $white;
            padding: 10px 25px;
            border-radius: 25px;
            @media (max-width: 710px) {
              white-space: inherit;
            } 

            &:hover,&:focus {
              outline: normal;
            }
           }
           &:nth-child(4n-3) {
             a {
               background: #214f2b;
             }
           }
           &:nth-child(4n-2) {
             a {
               background: #fec223;
             }
           }
           &:nth-child(4n-1) {
             a {
              background: #5c7b35;
             }
           }
           &:nth-child(4n) {
             a {
               background: #505050;
             }
           }

        }
      }
    }

    .img-text-container {
         display: flex;
         flex-wrap: wrap;
         @media (max-width: 710px) {
          justify-content: center;
        }
        .lt-img-wrapper {
          flex: 0 0 180px;
          @media (max-width: 710px) {
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
          }
          img {
            width: 140px;
          }
        }
        .rt-text-wrapper {
          flex: 1;
        }
    }

    .t-heading {
      color: #274120;
    }
    .content-bg-fff {
     
      > p {
       margin-top: 30px;
       padding-right: 65px;
        @media (max-width: 1080px) {
          padding-right: calc(2px + (65 - 2) * ((100vw - 375px) / (1080 - 375)));
        }
      }
      > div {
        margin-top: 30px;
      }
      ul {
        list-style-type: initial;
        padding-left: 15px;
        &.pl-30 {
          padding-left: 30px;
        }
        li {
         padding-left: 15px;
         margin-top: 30px; 
         line-height: 1.7;    
         ul {
           list-style-type: circle;
         }
         p + p {
           margin-top: 30px;
         }                                                                                                                                                                                                                                                                                                                                                                                                 
       }
      }
    }

  }
  &.page-more-tips-detail,
  &.page-nutrition-detail {
    .content-bg-fff {
      padding: 30px 35px 30px 100px;
        @media (max-width: 1080px) {
          padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(25px + (35 - 25) * ((100vw - 375px) / (1080 - 375)));
        }
      }
  }

  &.page-cooking-methods-detail {
    .content-bg-fff {
      padding: 30px 90px;
        @media (max-width: 1080px) {
          padding: calc(20px + (30 - 20) * ((100vw - 375px) / (1080 - 375))) calc(25px + (35 - 25) * ((100vw - 375px) / (1080 - 375)));
        }
      }
  }

  &.page-nutrition-detail {
     .content-bg-fff {
      > br {
        display: none;
      }
      > p {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
      }
      > .t-heading ~ .t-heading {
        margin-top: 40px;
      }
      > ul {
        margin-bottom: 30px;
       > li {
        padding-left: 10px;
      }
      } 
      
    
      > .t-heading + ul, > .t-heading + ul {
        padding-right: 25px;
        padding-left: 25px;
        margin-bottom: 0;
         @media (max-width: 1080px) {
           padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
           padding-left: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
         }
       }
       > .t-heading ~ p + ul {
        padding-right: 25px;
        padding-left: 50px;
         @media (max-width: 1080px) {
           padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
           padding-left: calc(30px + (50 - 30) * ((100vw - 375px) / (1080 - 375)));
         }
      }
        > h2.t-heading ~ p  {
        padding-right: 25px;
        padding-left: 30px;
        margin-bottom: 0;
         @media (max-width: 1080px) {
           padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
           padding-left: calc(15px + (30 - 15) * ((100vw - 375px) / (1080 - 375)));
         }
       }
     }
  }

   &.page-animal-welfare {
     .hash-link-wrapper {
       max-width: 780px;
       ul li a {
         background: #111111 !important;
       }
     }
     .content-bg-fff {
       h2.t-heading {
         color: #2f2f2f;
       }
      > p,h2.t-heading {
        padding-right: 25px;
        padding-left: 50px;
         @media (max-width: 1080px) {
           padding-right: calc(2px + (25 - 2) * ((100vw - 375px) / (1080 - 375)));
           padding-left: calc(15px + (50 - 15) * ((100vw - 375px) / (1080 - 375)));
         }
       }
       ul {
         margin-top: 30px;
         margin-bottom: 20px;
         padding-left: 70px;
         @media (max-width: 1080px) {
           padding-left: calc(35px + (70 - 35) * ((100vw - 375px) / (1080 - 375)));
         }
         li {
           padding-left: 0;
           margin-top: 0;
         }
       }
     }     
   }

}

