
@font-face {
  font-family: 'Karbon-Bold';
  src: url('../fonts/Karbon/Karbon-Bold.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Bold.woff') format('woff'), url('../fonts/Karbon/Karbon-Bold.ttf') format('truetype'),url('../fonts/Karbon/Karbon-Bold.otf') format('opentype'), url('../fonts/Karbon/Karbon-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-BoldItalic';
  src: url('../fonts/Karbon/Karbon-BoldItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-BoldItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-BoldItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-BoldItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-BoldItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Hairline';
  src: url('../fonts/Karbon/Karbon-Hairline.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Hairline.woff') format('woff'), url('../fonts/Karbon/Karbon-Hairline.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Hairline.otf') format('opentype'), url('../fonts/Karbon/Karbon-Hairline.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-HairlineItalic';
  src: url('../fonts/Karbon/Karbon-HairlineItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-HairlineItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-HairlineItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-HairlineItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-HairlineItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Light';
  src: url('../fonts/Karbon/Karbon-Light.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Light.woff') format('woff'), url('../fonts/Karbon/Karbon-Light.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Light.otf') format('opentype'), url('../fonts/Karbon/Karbon-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-LightItalic';
  src: url('../fonts/Karbon/Karbon-LightItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-LightItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-LightItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-LightItalic.otf') format('opentype') url('../fonts/Karbon/Karbon-LightItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Medium';
  src: url('../fonts/Karbon/Karbon-Medium.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Medium.woff') format('woff'), url('../fonts/Karbon/Karbon-Medium.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Medium.otf') format('opentype'), url('../fonts/Karbon/Karbon-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-MediumItalic';
  src: url('../fonts/Karbon/Karbon-MediumItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-MediumItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-MediumItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-MediumItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-MediumItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Regular';
  src: url('../fonts/Karbon/Karbon-Regular.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Regular.woff') format('woff'), url('../fonts/Karbon/Karbon-Regular.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Regular.otf') format('opentype'), url('../fonts/Karbon/Karbon-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-RegularItalic';
  src: url('../fonts/Karbon/Karbon-RegularItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-RegularItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-RegularItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-RegularItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-RegularItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Semibold';
  src: url('../fonts/Karbon/Karbon-Semibold.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Semibold.woff') format('woff'), url('../fonts/Karbon/Karbon-Semibold.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Semibold.otf') format('opentype'), url('../fonts/Karbon/Karbon-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-SemiboldItalic';
  src: url('../fonts/Karbon/Karbon-SemiboldItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-SemiboldItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-SemiboldItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-SemiboldItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-SemiboldItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-Thin';
  src: url('../fonts/Karbon/Karbon-Thin.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-Thin.woff') format('woff'), url('../fonts/Karbon/Karbon-Thin.ttf') format('truetype'), url('../fonts/Karbon/Karbon-Thin.otf') format('opentype'), url('../fonts/Karbon/Karbon-Thin.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon-ThinItalic';
  src: url('../fonts/Karbon/Karbon-ThinItalic.eot');
  src: local('☺'), url('../fonts/Karbon/Karbon-ThinItalic.woff') format('woff'), url('../fonts/Karbon/Karbon-ThinItalic.ttf') format('truetype'), url('../fonts/Karbon/Karbon-ThinItalic.otf') format('opentype'), url('../fonts/Karbon/Karbon-ThinItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Surt-Bold';
  src: url('../fonts/Surt-Bold/ATSurt-Bold.eot');
  src: local('☺'), url('../fonts/Surt-Bold/ATSurt-Bold.woff') format('woff'), url('../fonts/Surt-Bold/ATSurt-Bold.ttf') format('truetype'), url('../fonts/Surt-Bold/ATSurt-Bold.otf') format('opentype'), url('../fonts/Surt-Bold/ATSurt-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Surt-Regular';
  src: url('../fonts/Surt-Regular/ATSurt-Regular.eot');
  src: local('☺'), url('../fonts/Surt-Regular/ATSurt-Regular.woff') format('woff'), url('../fonts/Surt-Regular/ATSurt-Regular.ttf') format('truetype'), url('../fonts/Surt-Regular/ATSurt-Regular.otf') format('opentype'), url('../fonts/Surt-Regular/ATSurt-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Helvetica-Nueue-Arabic-Roman';
  src: url('../fonts/HelveticaNeueLT Arabic 55 Roman.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GESS-Text-Light';
  src: url('../fonts/GessLight/GESSTextLight.eot');
  src: local('☺'), url('../fonts/GessLight/GESSTextLight.woff') format('woff'),
   url('../fonts/GessLight/GESSTextLight.ttf') format('truetype'),
   url('../fonts/GessLight/GESSTextLight.otf') format('opentype'),
    url('../fonts/GessLight/GESSTextLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}