@media (max-width: 710px) {
  div[class^="breadcrum mb-"],
    div[class^="breadcrum mt-"] {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
}
.breadcrum {
  padding-top: 10px;
  padding-bottom: 25px;
  @media (max-width: 710px) {
    padding-bottom: 0;

  }
  ol {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: $black;
    @media (max-width: 710px) {
      display: none;
    }
    * {
      text-decoration: none;
      color: inherit;
    }
    li {
      &:last-of-type a::after{
      display: none;
      }
      &+ li {
        margin-left: 5px;
      }
      font-size: 15px;
      @media (max-width: 1920px) {
        font-size: calc(9px + (15 - 9) * ((100vw - 375px) / (1920 - 375)));
      }
      a {
        position: relative;
        font-family: $SR;
        font-size: inherit;
        &:after {
          content: ">";
          display: inline-block;
          vertical-align: bottom;
          pointer-events: none;
          margin-left: 5px;
        }
      }
      span {
        font-family: $SB;
        font-size: inherit;
      
      }
    }
  }
}
