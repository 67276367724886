.page-aussome-recipes-detail {
  header {
    padding-bottom: 30px;
    @media (max-width: 710px) {
      padding-bottom: 0;
    }
  }
  .breadcrum {
    padding: 30px 0;
    @media (max-width: 710px) {
      padding: 5px 0;
    }
  }
  .ingredients-container {
    max-width: 750px;
    overflow-x: auto; 
    justify-content: space-between;
    table {
      width: 750px;
      border: none !important;
      th,td {
        padding: 5px;
        border: none !important;
        text-align: left;
      }
      th {
        background: #f7f7f7;
      }
    }
    > p {
      flex: 0 0 47%;
      margin-bottom: 10px;
      font-size: 18px;
      padding-right: 1%;
      font-family: $SR;
      b {
        margin-top: 10px;
        @media (min-width: 711px) {
          display: inline-block;
          width: 600px;
        }
      }
      @media (max-width: 1080px) {
        font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375))) 
      }
      @media (max-width: 710px) {
        flex: 0 0 100%;
        margin-bottom: 5px;
        padding-right: 0;
      }
    }
  }
  .grid-container {
    .grid-row {
      flex-wrap: wrap;
      background: $white;
      padding: 5px;
      border-radius: 30px;
      background-clip: padding-box;
      margin-bottom: 25px;
      @media (max-width: 710px) {
        justify-content: center;
      }
      .grid-col {
        &:nth-of-type(1) {
          flex: 0 0 67%;
          @media (min-width: 1024px) and (max-width: 1050px) {
            flex: 0 0 60%;
          }
          @media (min-width: 711px) and (max-width: 1023px) {
            flex: 0 0 55%;
          }
          @media (max-width: 710px) {
            flex: 0 0 100%;
          }
        }
        &:nth-last-of-type(1) {
          flex: 0 0 32%;
          @media (max-width: 710px) {
            flex: 0 0 100%;
          }
        }
        .img-wrap {
          position: relative;
          border-radius: 30px;
          overflow: hidden;
          background-clip: padding-box;
          picture img {
            width: 100% !important;
            height: 409px;
            object-fit: cover;
            transform: scale(1.1,1.1);
            @media (min-width: 1024px) and (max-width: 1050px) {
              height: 300px;
            }
            @media (min-width: 711px) and (max-width: 1023px) {
              height: calc(255px + (300 - 255) * ((100vw - 711px) / (1023 - 711)));
            }
            @media (max-width: 710px) {
              height: 255px;
            }
          }
          .vedio-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            display: inline-block;
            cursor: pointer;
            img {
              box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 30%);
              border-radius: 100%;
            }
          }
        }
        .content-wrap {
          padding: 15px;
          height: 100%;
          @media (max-width: 710px) {
            justify-content: center;
          }
          h1 {
            font-size: 26px;
            color: #7f1718;
            text-align: center;
            font-family: $SB;
            @media (max-width: 1080px) {
              font-size: calc(16px + (26 - 16) * ((100vw - 375px) / (1080 - 375)));
            }
          }
        }
      }
    }
  }
}