.not-front {
  &.page-thisramadan {
    .recipes-tabs {
      padding: 0 45px;
      margin-bottom: 25px;
      @media (max-width: 1080px) {
        margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
        padding: 0 calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
      }
    }
    .bbq-intro {
      h2.heading {
        font-size: 35px;
        font-family: $SB;
        @media (max-width: 1080px) {
          font-size: calc(21px + (35 - 21) * ((100vw - 375px) / (1080 - 375)));
        }
      }
      p {
        font-size: 26px;
        font-family: $SR;
        color: #58595b;
        margin-bottom: 15px;
        @media (max-width: 1080px) {
          font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
        }
        &.g-text-color {
          color: #5c7b35;
          @media (max-width: 1080px) {
           font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
          }
        }
      }
    }
    .section-ql {
      padding-top: 30px;
      padding-bottom: 20px;
      @media (max-width: 1080px) {
        padding-top: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));
      }
    }

    section {
      padding: 2px;
      border-radius: 25px;
      background-clip: padding-box;
      margin-bottom: 50px;
      // background: #f2fddd;
      @media (max-width: 1080px) {
        margin-bottom: calc(25px + (50 - 25) * ((100vw - 375px) / (1080 - 375)));
      }
      &#Cuts-for-popular-Ramadan-dishes,
      &#grilling-recipes {
        // background: #fff8e7;
        background: #fdf3da;

        .card-main-wrapper {
          padding: 0 15px;
        }
        .loadmore button {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      
      &#marinades-to-try {
        background-color: #eff5e5;
        .card-main-wrapper {
          padding: 0 15px;
        }
        
      }
      .section-header {
        // background: #556b2a;
        background-color: #274120;
        border-radius: 25px;
        background-clip: padding-box;
        padding: 30px 45px;
        margin-bottom: 25px;
        @media (max-width: 1080px) {
          margin-bottom: calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375)));
          padding: calc(13px + (30 - 13) * ((100vw - 375px) / (1080 - 375))) calc(25px + (45 - 25) * ((100vw - 375px) / (1080 - 375)));
        }
        @media (max-width: 767px) {
          text-align: center;
        }
        // &.bg-middle {
        //   background: #c39300;
        // }
        * {
          color: $white;
        }
        h2 {
          font-family: $SB;
          font-size: 35px;
          @media (max-width: 1080px) {
           font-size: calc(11px + (35 - 11) * ((100vw - 375px) / (1080 - 375)));
          }
        }
        p {
          font-size: 26px;
          font-family: $SR;
          @media (max-width: 1080px) {
           font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
          }
          a {
            text-decoration: none;
            color: #ffbf00;
          }
        }
      }
      .step-heading {
        color: $white;
        font-size: 24px;
        font-family: $SB;
        display: inline-block;
        background: #adc154;
        border-radius: 25px;
        overflow: hidden;
        margin:20px 0 35px 30px;
         @media (max-width: 1080px) {
           margin-top: calc(1px + (20 - 1) * ((100vw - 375px) / (1080 - 375)));
           margin-bottom: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
           margin-left: calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
           font-size: calc(8px + (24 - 8) * ((100vw - 375px) / (1080 - 375)));
         }
        span {
          padding: 7px 15px;
          display: inline-block;
          &:nth-last-of-type(1) {
            padding-right: 50px;
            background: #5f7b29;
          }
        }
      }
     
      .tips-heading {
        font-size: 33px;
        font-family: $SB;
        color: #7a121a;
        margin-bottom: 35px;
        @media (max-width: 1080px) {
          font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
          margin-bottom: calc(17px + (35 - 17) * ((100vw - 375px) / (1080 - 375)));
        }
      }
      .tips-content {
        justify-content: space-between;


        > div {
          max-width: calc(50% - 15px);
          flex: 0 0 50%;
          @media (max-width: 710px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 25px;
          }
          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 35px;
            @media (max-width: 1080px) {
              height: calc(128px + (300 - 128) * ((100vw - 375px) / (1080 - 375)));
            }
            @media (max-width: 710px) {
              margin-left: -10px;
              margin-right: -10px;
            }
          }
          h2 {
            font-family: $SB;
            font-size: 33px;
            color: #607c29;
            padding: 25px 0 20px;
            @media (max-width: 1080px) {
              font-size: calc(16px + (33 - 16) * ((100vw - 375px) / (1080 - 375)));
              padding:  calc(15px + (25 - 15) * ((100vw - 375px) / (1080 - 375))) 0;
            }
            @media (max-width: 710px) {
              br {
                display: none;
              }
            }
            
          }
          p {
            font-size: 26px;
            font-family: $SR;
            color: #58595b;
            @media (max-width: 1080px) {
             font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
            }
            @media (max-width: 710px) {
              padding-right: 15px;
              br {
                display: none;
              }
            }
            
          }
        }
      }
      &#learn-the-basics {
        > div {
          background: #fff;
          border-radius: 35px;
          margin-bottom: 30px;
          &:nth-of-type(1) {
            padding-bottom: 30px;
          }
          &:nth-of-type(2) {
            padding-top: 20px;
            .bg-card-wrapper .bg-card-header picture img {
              @media(min-width: 1920px) {
               height: 380px;
              }
              
            }
          }
          &#pro-tips {
            padding: 20px 1px 0px;
            overflow: hidden;
            margin-bottom: 0;
          }
        }
      }
      .accordionWrapper {
        padding: 0 30px;
        @media (max-width: 1080px) {
         padding: 0 calc(15px + (35 - 15) * ((100vw - 375px) / (1080 - 375)));
        }
        .accordionItem {
          .accordionItemHeading {
          font-size: 26px;
          font-family: $SB;
          color: #575756;
          padding-bottom: 10px;
          border-bottom: 1px solid;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          @media (max-width: 1080px) {
            font-size: calc(12px + (26 - 12) * ((100vw - 375px) / (1080 - 375)));
          }
          &:after {
            content: "+";
            display: inline-block;
            position: relative;
            font-family: $SR;

          }

        }
        .accordionItemContent {
          height:0px;
          transition:height 1s ease-out;
          padding: 20px;
          display: block;
          transform: scaleY(0);
          width: 100%;
          overflow-x: auto;
          @media (max-width: 1080px) {
            padding: 10px;
          }
          .content-wrap {
            padding: 30px 0;
            align-items: flex-start;
            justify-content: center;
            align-items: stretch;
            direction: ltr;
            @media (max-width: 1080px) {
              padding: 0;
            }
            @media (max-width: 710px) {
              justify-content: flex-start;
              picture,img {
                width: 100%;
              }
            }
            > div {
              max-width: calc(25% - 1px);
              flex: 0 0 25%;
              border-right: 1px solid #adc154;
              @media (max-width: 710px) {
                flex: 0 0 33.33%;
                max-width: calc(33.33% - 1px);
              }
              &:nth-child(4n) {
                @media (min-width: 711px) {
                 border-right: 0;
                }
                
              }
              &:nth-child(3n) {
                @media (max-width: 710px) {
                  border-right: 0;
                }
              }
              &:nth-child(4),&:nth-child(5),&:nth-child(6) {
                @media (max-width: 710px) {
                  border-top: 1px solid #adc154;
                  border-bottom: 1px solid #adc154;
                }
              }
              &:nth-child(n + 10) {
                @media (max-width: 710px) {
                  border-top: 1px solid #adc154;
                }
              }
              &:nth-child(n + 5) {
                @media (min-width: 711px) {
                  border-top: 1px solid #adc154;
                }
                
              }
              .text-wrap {
                font-size: 15px;
                font-family: $SB;
                color: #2d4024;
                text-align: center;
                padding: 0 10px 10px;
                @media (max-width: 1080px) {
                  font-size: calc(8px + (15 - 8) * ((100vw - 375px) / (1080 - 375)));
                }

              }
              .img-wrap {
                width: 156px;
                height: 156px;
                margin: 0 auto;
                @media (max-width: 1080px) {
                  width: calc(80px + (156 - 80) * ((100vw - 375px) / (1080 - 375)));
                  height: calc(75px + (156 - 75) * ((100vw - 375px) / (1080 - 375)));
                }
              }
              
            }
            &.lamb-cuts {
              > div {
                .img-wrap {
                  background: url("../web-images/bbq/lamb-accordion.png") no-repeat;
                  @media (max-width: 1080px) {
                    background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
                  }

                }
                &:nth-of-type(1) .img-wrap {
                  background-position: -20px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(2) .img-wrap {
                  background-position: -254px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(3) .img-wrap {
                  background-position: -522px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-205px + (-522 - (-205)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }      
                &:nth-of-type(4) .img-wrap {
                  background-position: -772px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(10px + (1 - (10)) * ((100vw - 375px) / (1080 - 375)));
                  }
                } 
                &:nth-of-type(5) .img-wrap {
                  background-position: -20px -195px;
                  @media (max-width: 1080px) {
                    background-position: calc(0px + (-20 - (0)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }  
                &:nth-of-type(6) .img-wrap {
                  background-position: -254px -195px;
                  @media (max-width: 1080px) {
                    background-position: calc(-105px + (-254 - (-105)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }    
                &:nth-of-type(7) .img-wrap {
                  background-position: -522px -195px;
                  @media (max-width: 1080px) {
                    background-position: calc(-204px + (-522 - (-204)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }      
                &:nth-of-type(8) .img-wrap {
                  background-position: -772px -195px;
                  @media (max-width: 1080px) {
                    background-position: calc(-300px + (-772 - (-300)) * ((100vw - 375px) / (1080 - 375))) calc(-68px + (-195 - (-68)) * ((100vw - 375px) / (1080 - 375)));
                  }
                } 
                  &:nth-of-type(9) .img-wrap {
                  background-position: -20px -390px;
                  @media (max-width: 1080px) {
                    background-position: calc(-1px + (-20 - (-1)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(10) .img-wrap {
                  background-position: -254px -390px;
                  @media (max-width: 1080px) {
                    background-position: calc(-100px + (-254 - (-100)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(11) .img-wrap {
                  width: 225px;
                  background-position: -490px -390px;
                  @media (max-width: 1080px) {
                    //background-size: calc(400px + (919 - 400) * ((100vw - 375px) / (1080 - 375)));
                    width: calc(100px + (225 - (100)) * ((100vw - 375px) / (1080 - 375)));
                    background-position: calc(-195px + (-490 - (-195)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }      
                &:nth-of-type(12) .img-wrap {
                  background-position: -772px -390px;
                  @media (max-width: 1080px) {
                    background-position: calc(-306px + (-772 - (-306)) * ((100vw - 375px) / (1080 - 375))) calc(-150px + (-390 - (-150)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }                                                                    
                
              }
            }
            &.beef-cuts {
              > div {
                .img-wrap {
                  width: 195px;
                  background: url("../web-images/bbq/beef-accordion.png") no-repeat;
                  @media (max-width: 1080px) {
                   width: calc(95px + (195 - 95) * ((100vw - 375px) / (1080 - 375)));
                   background-size: calc(375px + (919 - 375) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(9),&:nth-of-type(12),&:nth-of-type(10),&:nth-of-type(11) {
                  @media (min-width: 711px) {
                    border-bottom: 1px solid #adc154;
                  }
                }
                 &:nth-of-type(13),&:nth-of-type(14) {
                  border-top: 0;
                 }
                 &:nth-of-type(13) {
                   @media (min-width: 711px) {
                     border-left: 1px solid #adc154;
                   }
                 }
                 &:nth-of-type(12),&:nth-of-type(10),&:nth-of-type(11) {
                   @media (max-width: 710px) {
                     border-bottom: 1px solid #adc154;
                   }
                 }
                &:nth-of-type(1) .img-wrap {
                  background-position: -20px -20px;
                  @media (max-width: 1080px) {
                    background-position: calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375))) calc(5px + (-20 - (5)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(2) .img-wrap {
                  background-position: -254px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-90px + (-254 - (-90)) * ((100vw - 375px) / (1080 - 375))) 5px;
                  }
                }
                &:nth-of-type(3) .img-wrap {
                  width: 215px;
                  background-position: -507px 0;
                  @media (max-width: 1080px) {
                    width: calc(95px + (215 - (95)) * ((100vw - 375px) / (1080 - 375)));
                    background-position: calc(-200px + (-507 - (-200)) * ((100vw - 375px) / (1080 - 375))) 5px;
                  }
                }      
                &:nth-of-type(4) .img-wrap {
                  background-position: -745px 0;
                  @media (max-width: 1080px) {
                    background-position: calc(-295px + (-745 - (-295)) * ((100vw - 375px) / (1080 - 375))) 5px;
                  }
                } 
                &:nth-of-type(5) .img-wrap {
                  background-position: 2px -210px;
                   @media (max-width: 1080px) {
                    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
                  }
              
                }  
                &:nth-of-type(6) .img-wrap {
                  background-position: -254px -210px;
                  @media (max-width: 1080px) {
                    background-position: calc(-95px + (-254 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }    
                &:nth-of-type(7) .img-wrap {
                  background-position: -506px -210px;
                  @media (max-width: 1080px) {
                    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }      
                &:nth-of-type(8) .img-wrap {
                  background-position: -762px -210px;
                  @media (max-width: 1080px) {
                    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-85px + (-210 - (-85)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }
                &:nth-of-type(9) .img-wrap {
                  background-position: 2px -430px;
                  @media (max-width: 1080px) {
                    background-position: calc(10px + (2 - 10) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }  
                &:nth-of-type(10) .img-wrap {
                  background-position: -245px -430px;
                  @media (max-width: 1080px) {
                    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }    
                &:nth-of-type(11) .img-wrap {
                  background-position: -506px -430px;
                  @media (max-width: 1080px) {
                    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }      
                &:nth-of-type(12) .img-wrap {
                  background-position: -762px -430px;
                  @media (max-width: 1080px) {
                    background-position: calc(-297px + (-762 - (-297)) * ((100vw - 375px) / (1080 - 375))) calc(-165px + (-430 - (-165)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }  
                &:nth-of-type(13) .img-wrap {
                  background-position: -245px -630px;
                  @media (max-width: 1080px) {
                    background-position: calc(-95px + (-245 - (-95)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }    
                &:nth-of-type(14) .img-wrap {
                  background-position: -506px -630px;
                  @media (max-width: 1080px) {
                    background-position: calc(-201px + (-506 - (-201)) * ((100vw - 375px) / (1080 - 375))) calc(-240px + (-630 - (-240)) * ((100vw - 375px) / (1080 - 375)));
                  }
                }                                                                                           
                
              }
            }            
          }
        }
        &.open {
          .accordionItemHeading:after {
            content: "-";
            color: #adc154;
          }
          .accordionItemHeading {
            border-color: #adc154;
          }
          .accordionItemContent {
            transform: scaleY(1);
            height: auto;
          }
        }
        }

      }
    
      .pro-tips-item {
        margin-bottom: 30px;
        border-radius: 35px;
        overflow: hidden;
        padding: 45px 30px;
        background: #f1f8e3;
        @media (max-width: 1080px) {
          padding: calc(20px + (45 - 20) * ((100vw - 375px) / (1080 - 375))) calc(15px + (30 - 15) * ((100vw - 375px) / (1080 - 375)));
          margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1080 - 375)));;
        }
        &.while-you-grill {
          background: #fef5dc;
        }
        &.after-grilling {
          margin-bottom: 0;
        }
      }
    }
  }
  .ramadan-recipies-paragraph {
    margin-top: 25px;
  }
  .ramadan-dishes-paragraph {
    margin: 0 45px;
    margin-bottom: 25px;
  }
  section#tip-videos {
    // background: #f3fce2;
     background: #fdf3da;
    .tip-videos-paragraph{
      margin-bottom: 3rem;
      padding: 0 25px;
    }
    .vid-wrap{
      padding: 0 25px;
    }
  }

}
 /* Designing for scroll-bar */
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
      
        /* Track */
        ::-webkit-scrollbar-track {
            background: gainsboro;
            border-radius: 5px;
        }
      
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #274120;
            border-radius: 5px;
        }
      
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .bg-card-wrapper .bg-card-header picture img {
          .page-bbq & {
            height: 380px;
            @media (max-width: 1080px) {
              height: calc(128px + (380 - 128) * ((100vw - 375px) / (1080 - 375)));
            }
          }
        }
        .rmdan_dishes_bg{
          text-align: center;
          background: transparent;
        }
        .tip-videos-paragraph {
          margin-bottom: 3rem;
        }
        section#Ramadan-recipes {
          background:#fdf3da;
        }
              

