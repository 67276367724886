.page-banner {
  margin-top: -113px;
  z-index: 0;
  @media (max-width: 710px) {
    body.open-search & {
      margin-top: -125px;
    }
  }
  .banner-container {
    position: relative;
    .not-front & {
       &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../web-images/gradiant.png");
      background-repeat: no-repeat;
      background-position: top left;
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      @media(min-width: 1921px) {
        background-size: 100%;
      }
    }
    }
   
     img {
       height: 475px;
       @media (max-width: 1920px) {
        height: calc(315px + (470 - 315) * ((100vw - 375px) / (1920 - 375)));
       }
       width: 100% !important;
       object-fit: cover;
       object-position: top right;
       .page-resource-centre &, .node-foodservice &  {
         object-position: center center;
       }
       .page-aussome-recipes-inner & {
        object-position: left;
        @media (max-width: 710px) {
          object-position: center center;
        }
       }
    
     }
     .caption-txt {
      position: absolute;
      width: 100%;
      left: 50%;
      right: 50%;
      transform: translate(-50%,0);
      top: 41%;
      @media (max-width: 710px) {
        top: 36%;
      }
      h1 {
        font-size: 40px;
        line-height: 1.2;
        font-family: $SB;
        color: $green;
        @media (max-width: 710px) {
          line-height: 1.5em;
        }
        .node-foodservice & {
          color: #2a2a2a;
        }
        .node-butchery & {
          color: #541818;
        }
        margin-bottom: 25px;
        letter-spacing: 1;
        width: 600px;
        &.w-335,&.w-375, .page-aussome-recipes &, .page-cooking-methods &,
        .page-cuts-by-protien & {
          width: 380px;
          @media (max-width: 710px) {
            width: 100%;
          }
          .page-menu-inspiration & {
            @media (max-width: 710px) {
              width: 200px;
            }
          }
        }
        .page-meatcuts-inner & {
          width: 320px;
          @media (min-width: 711px) {
            width: calc(220px + (320 - 220) * ((100vw - 711px) / (1920 - 711)));
          }
          @media (max-width: 710px) {
            width: 100%;
          }
        }

        &.w-350 {
          width: 350px;
          @media (min-width: 711px) {
            width: calc(250px + (350 - 250) * ((100vw - 711px) / (1920 - 711)));
          }
          @media (max-width: 710px) {
            width: 100%;
          }
        }

        @media (min-width: 711px) and (max-width: 1920px) {
          width: calc(480px + (480 - 280) * ((100vw - 711px) / (1920 - 711)));
          .page-aussome-recipes &,
          .page-cooking-methods &,
            &.w-375 {
              width: calc(380px + (380 - 280) * ((100vw - 711px) / (1920 - 711)));
            }
            &.w-335 {
              width: calc(380px + (380 - 280) * ((100vw - 711px) / (1920 - 711)));

            }

        }
        @media (max-width: 1920px) {
          font-size: calc(21px + (40 - 21) * ((100vw - 375px) / (1920 - 375)));
        }
        @media (max-width: 710px) {
          position: relative;
          z-index: 0;
          margin-bottom: 15px;
          width: 300px !important;
        }
        @media (min-width: 711px) and (max-width: 1024px) {
          width: 600px !important;
        }
      }
    }
   
    .not-front & {
        .caption-txt h1 {
         span {
           background-color: #fff;
            padding: 2px 25px 2px 25px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
            line-height: 1.5em;
            border-radius: 20px;
            @media (max-width: 710px) {
              line-height: 1.7;
              position: relative;
            }
         }
        }
      
    }
      
    .page-resources & {
      .bg-card-wrapper {
        margin-bottom: 0;
        border-radius: 0;
        padding: 0;
        .bg-card-header {
          img {
            height: 475px;
            border-radius: 0;
            @media (max-width: 1920px) {
              height: calc(315px + (470 - 315) * ((100vw - 375px) / (1920 - 375)));
            }
          }
          &.img-wrap div.vedio-icon {
             z-index: 100;
             a {
               display: inline-block;
               img {
            width: 100px;
            height: 100px;
            @media (max-width: 1920px) {
              width: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
              height: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
            }
             }
           } 
          }
        }
      }
    }
    
  }
}

header.menu-open + main .slideshow .mySlides .caption-txt {
  z-index: 0;
}

