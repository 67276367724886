.slideshow {
  margin-top: -110px;
  z-index: 0;
   &:before {
    content: "";
    z-index: 1;
    width: 100%;
    height: 460px;
    background: url(../web-images/gradiant.png);
    background-repeat: no-repeat;
    background-position: top left;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    @media (min-width: 711px) {
     background-size: 100%;
    }
  }
  .mySlides {
    &:first-child {
      .caption-txt {
        h2 {
          @media (min-width: 711px) and (max-width: 1920px) {
            width: calc(340px + (340 - 360) * ((100vw - 711px) / (1920 - 711)));
          }
        }
      }
    }
    position: relative;
    .caption-txt {
      position: absolute;
      width: 100%;
      left: 50%;
      right: 50%;
      transform: translate(-50%,0);
      top: 20%;
      z-index: 999;
      @media (max-width: 710px) {
        top: 12%;
      }
      h2 {
        font-size: $f40;
        line-height: 1.2;
        font-family: $SB;
        color: $green;
        margin-bottom: 25px;
        width: 340px;
      
       
        @media (max-width: 1920px) {
          font-size: calc(25px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
        }
        @media (min-width: 711px) and (max-width: 1920px) {
          width: calc(240px + (340 - 240) * ((100vw - 711px) / (1920 - 711)));
        }
        @media (max-width: 710px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .action-wrapper {
        a {
           display: inline-block;
           padding: 13px 25px 10px;
           background: $white;
           color: $green;
           font-family: $KSB;
           border-radius: 25px;
           font-size: $f19;
           white-space: nowrap;
           @media (max-width: 1920px) {
            font-size: calc(14px + (19 - 14) * ((100vw - 375px) / (1920 - 375)));
           }
           
           text-decoration: none;
           
        }
      }
    }
    &:nth-of-type(1) {
      .caption-txt {
        h2 {
          color: white;
          text-shadow: 0.1px 0.1px rgba(0,0,0,0.3);
        }
      }
    }
    img {
       @media (min-width: 711px) {
        height: 813px;
        width: 100% !important;
        object-fit: cover;
       }
     
       @media (max-width: 710px) {
        width: 100% !important;
        object-position: top;
        height: 812px;
        object-fit: cover;
       }
       @media (min-width: 1921px) {
        width: 100% !important;
       }
    }
  }
  .slideshow-indicators {
    text-align: center;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    transform: translate(-50%,0);
    width: 100%;
    @media (max-width: 710px) {
      display: none;
    }
    span {
      width: 7px;
      height: 7px;
      border-radius: 8px;
      background: #D8CCC7;
      border: 1px solid $gray;
      display: inline-block;
      &.active {
        background: #FDCF57;
      }
      &+ span {
        margin-left: 8px;
      }
    }
  }

}
/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 0.1s;
  animation-name: fade;
  animation-duration: 0.1s;
}
/* .fade .caption-txt h2{
  -webkit-animation-name: fade1;
  -webkit-animation-duration: 1s;
  animation-name: fade1;
  animation-duration: 1s;
} */
@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
@-webkit-keyframes fade1  {
  from {transform: scale(1.1,1.1)} 
  to {transform: scale(1,1)}
}

@keyframes fade1 {
from {transform: scale(1.1,1.1)} 
  to {transform: scale(1,1)}
}

.pp-slider {
  h2.t-center {
    text-align: center;
  }
  @media (min-width: 711px) {
     padding-left: 90px;
     padding-right: 90px;
  }

  &.slick-slider {
    padding-bottom: 0;
    .slick-slide.slick-active {
      -webkit-animation-name: fade;
      -webkit-animation-duration: 1.5s;
      animation-name: fade;
      animation-duration: 1.5s;      
    }
    .slick-arrow {
      bottom: 0;
      width: 46px;
      height: 112px;
      @media (max-width: 710px) {
        top: 0;
        width: 20px;
        height: 50px;
      }
      &.slick-prev {
        background: url("../web-images/left-arrow.png") no-repeat;
        background-size: 100%;
        left: 10px;
        background-position: 0 0;
      }
      &.slick-next {
        background: url("../web-images/right-arrow.png") no-repeat;
        background-size: 100%;
        left: 93%;
        background-position: 0 0;
      }
    }
  }
  
}