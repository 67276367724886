.not-front {
    &.page-contact,
    &.page-steakmate,
    &.page-roastmate {
        ::placeholder {
            color: #9c9c9c;
        }
        .t-heading {
            color: #214f2b;
        }
        .mla-worldwide-locations {
           padding-bottom: 100px;
        }
        .form-wrapper {
            margin-bottom: 50px;
          .contact-info {
              padding-left: 30px;
              .t-heading {
                  margin-bottom: 10px;
              }
          }
          .form-control button,.form-control a.btn  {
                display: inline-block;
                padding: 9px 25px;
                background: #fec839;
                color: $green;
                font-family: $SB;
                border-radius: 25px;
                font-size: 18px;
                white-space: nowrap;
                margin-bottom: 20px;
                border: none;
								text-decoration: none;
								outline: none;
                box-shadow: none;
                @media (max-width: 1920px) {
                 font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
                }
                outline: none;
            }
            .d-flex {
                justify-content: space-between;
                > div {
                    flex: 0 0 48%;
                    @media (max-width: 710px) {
                        flex: 0 0 100%;
                    }

                    label {
                        width: 100%;
                        display: inline-block;
                        margin-bottom: 20px;
                        padding-left: 30px;
                    }
                    input,select,textarea {
                        background-color: #f7f7f7;
                        display: inline-block;
                        box-shadow: none;
                        border: none;
                        outline: none;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 30px;
                        margin-bottom: 20px;
                        width: calc(100% - 60px);
                        border-radius: 30px;
                        font-size: 18px;
                        @media (max-width: 1080px) {
                            font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1080 - 375)));
                         }
                        color: #9c9c9c;
                        
                    }

                    select {
                        width: 100%;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                        background-repeat: no-repeat;
                        background-position-x: calc(100% - 30px);
                        background-position-y: 11px;
                    }

                    textarea {
                      height: 300px;
                      resize: none;
                      line-height: 1.5;
                      padding-top: 20px;
                      @media(max-width: 710px) {
                          height: 200px;
                      }
                    }
                }
            }
        }
    }
}
.not-front.page-steakmate .form-wrapper .d-flex ,
.not-front.page-roastmate .form-wrapper .d-flex{
  > div {
		label.t-heading {
		padding-left: 0;
	}
  select {
    color: #000;
    background-image: url("../web-images/select-icon.svg");
    background-position-y: 20px;
  }
  input#smThicknessSelect {
    -webkit-appearance: none;
    width: 100%;
    height: 16px;
    background: #f7f7f7;
    outline: none;
		padding: 0;
		margin-bottom: 33px;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
			appearance: none;
			width: 16px;
			height: 16px;
			background: #214f2b;
			cursor: pointer;
			border-radius: 50%;
			border: none;
			box-shadow: none;
    }
		&::-moz-range-thumb {
			width: 16px;
			height: 16px;
			background: #214f2b;
			cursor: pointer;
			border-radius: 50%;	
			border: none;
			box-shadow: none;					
		}
  }
	
}
 align-items: flex-end;
}

.not-front.page-steakmate, .not-front.page-roastmate {
  .lity-content {
    border-radius: 30px;
    @media (max-width: 710px) {
      border-radius: 20px;
    }
    overflow: hidden;
  }
 .content-bg-fff {
   &#smOutput {
     .screenshots {
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       @media (max-width: 1000px) {
         justify-content: center;
       }
       
       img {
         width: 300px;
         margin-bottom: 10px;
         box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.5);
         @media (max-width:1000px) {
           margin: 10px;
         }
       }
     }
   }
	ul {
		padding-left: 30px;
		@media (max-width: 710px) {
			padding-left: 15px;
		}
		list-style: none;
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      background: #ccc;
      position: absolute;
      left: 15px;
      top: 0;
      z-index: -1;
      display: inline-block;
      @media (max-width: 710px) {
        left: 0;
      }
    }
		li {
			margin-bottom: 20px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-clip: padding-box;
        background: #000;
        position: absolute;
        left: -17px;
        top: 7px;
        z-index: 0;
        display: inline-block;
      }
			span.flag {
				font-family: $SB;
			}
		}
	}
}
}
.not-front.page-roastmate .form-wrapper .d-flex {
  align-items: flex-start;
}