.scroll-to-top {
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: right;
  svg {
    cursor: pointer;
    opacity: 0;
    transform: translateY(200px);
    transition: all ease 0.5s;
  }
  &.showBtn {
    svg {
      opacity: 1;
      transform: translateY(0);
    }
  }
}